import React, { useEffect, useState } from 'react';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import Config from '../../../../core/config';
import { makeRequestWihActualStatus } from '../../../../core/services/v1/request';
import { Spinner } from 'flowbite-react';
import { toast } from '../../../../core/lib/toastAlert';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const BuyTab = ({ pairDetails, getInstabuyTrade, userWallet, getUserBalance }) => {
    const { price = 0, pair = '' } = pairDetails;
    const naviagte = useNavigate();
    const { siteSettings, user } = useGlobalContextData()
    const [spentbuycryptoinrprice, setspentbuycryptoinrprice] = useState();
    const [buycryptofees, setbuycryptofees] = useState('0%');
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // console.log('s ',siteSettings)
    //     if (siteSettings) {
    //         setbuycryptofees(siteSettings['BuyCryptoFees'] + '%');
    //     }
    // }, [siteSettings])

    const buyEnterAmount = (event) => {
        // let fees = ''
        // if (siteSettings) {
        //     fees = Number(siteSettings['BuyCryptoExtraPriceForBuy']);
        // }
        // const twoPercent = parseFloat(price) * fees; // calculate 2% of the number
        // const result = parseFloat(price) + twoPercent; // add 2% to the number
        // // setbuycryptoinrprice(result?.toFixed(2));

        // let percentage = event.target.value / result?.toFixed(2) * fees / 100;

        // let amount = event.target.value / result?.toFixed(2) - percentage;

        // // console.log(fees, "fees");
        // setspentbuycryptoinrprice((amount).toFixed(6) + ' ' + 'UNITIC');

        let result = event.target.value / parseFloat(price);
        // result = result - (result * 0.004);
        setspentbuycryptoinrprice((result).toFixed(2) + ' ' + 'UNITIC')
    }

    const onSubBuy = async (e) => {
        e.preventDefault();
        if (e.target.currencyamount.value > userWallet?.amount) {
            return toast({ type: 'error', message: 'Insufficient Balance' })
        }
        setLoading(() => true)
        let data = {
            userId: user?._id,
            currencyName: 'INR',
            currencyamount: e.target.currencyamount.value,
            cryptoamount: spentbuycryptoinrprice,
            address: user?.walletAddress,
            type: "buy",
        }
        // console.log(data, "data");
        const params = {
            url: `${Config.V1_API_URL}insta-trade/buy`,
            method: 'POST',
            body: data
        }

        const response = (await makeRequestWihActualStatus(params));
        if (response.status) {
            toast({ type: 'success', message: response.message })
            getInstabuyTrade()
            getUserBalance();
            e.target.reset()
            setspentbuycryptoinrprice(null)
            setLoading(() => false)
        }
        else {
            toast({ type: 'error', message: response.message })
            setLoading(() => false)
        }
        setLoading(() => false)
    }

    return (
        <div className='pt-5 pb-2'>
            <form onSubmit={onSubBuy} className='dProfile-form-inputs mx-auto px-3 md:px-4'>
                <label className='text-sm md:text-base'>Coin</label><br />
                <select className='special-custom' required>
                    <option className='special-custom' value={(pair)?.split('_')[0]} key={(pair)?.split('_')[0]}>{(pair)?.split('_')[0]}</option>
                </select>
                <br />
                <label className='text-sm md:text-base'>Price</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='text' value={price} readOnly required />
                    <span className='absolute right-5 text-sm cursor-pointer font-pop' onClick={(() => { })} style={{ bottom: '40%' }}>Max</span>
                </div>
                <label className='text-sm md:text-base'>Enter Amount</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='number' name='currencyamount' onChange={buyEnterAmount} required />
                    <span className='absolute right-8 text-sm font-pop' style={{ bottom: '40%' }}>INR</span>
                    <span className='absolute right-3 text-sm cursor-pointer font-pop' style={{ bottom: '40%' }} onClick={(() => naviagte('/dashboard/deposit-withdraw'))}><AddIcon fontSize='small' /></span>
                </div>
                <label className='text-sm md:text-base relative -top-2'>INR Balance: {userWallet?.amount} {userWallet?.currency}</label><br />
                <label className='text-sm md:text-base'>Enter Address</label><br />
                <input className='text-sm md:text-base' type='text' name='address' value={user?.walletAddress} readOnly required /> <br />
                <div className='flex justify-between items-center relative -top-2'>
                    <label className='text-sm md:text-base'>You Get: {spentbuycryptoinrprice}</label>
                    <label className='text-sm md:text-base'>Fees: {buycryptofees}</label>
                </div>
                <button type='submit' className='btn-theme-color text-base' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                    <span className="pl-3">Loading...</span></> : 'Submit'}</button>
            </form>
            {/* <ResultTable /> */}
        </div>
    );
};

export default BuyTab;