import React, { useEffect, useRef, useState } from 'react';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Spinner } from 'flowbite-react';
import { makeRequestWithErrorMessage } from '../../../../core/services/v1/request';
import Config from '../../../../core/config';
import RecordTable from './RecordTable';
import { toast } from '../../../../core/lib/toastAlert';

const Deposit = ({ record, bankDetails, getDepositRecord }) => {
    const [loading, setLoading] = useState(false);
    const fileInputRef1 = useRef(null);
    const [previewImg, setPreviewImg] = useState('');
    const [ss, setSs] = useState('')

    const selectFile = (event) => {
        const file = event.target.files[0];
        setSs(() => file?.name || '')
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImg(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleLabelClick1 = () => {
        fileInputRef1?.current.click();
    };

    const clearPreview1 = () => {
        setPreviewImg(() => '');
        setSs(() => '');
        if (fileInputRef1.current) {
            fileInputRef1.current.value = '';
        }
    };

    const onSubTransDeposit = async (e) => {
        e.preventDefault();
        setLoading(() => true)
        const formData = new FormData();
        formData.append('amount', e.target.enterAmount.value);
        formData.append('txnId', e.target.transactionNumber.value);
        formData.append('attachment', e.target.attachment.files[0]);
        formData.append('currency', 'INR');

        const params = {
            url: `${Config.V1_API_URL}user-wallet/create-trans?type=deposit`,
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            toast({ type: 'success', message: res.message })
            e.target.reset();
            clearPreview1();
            getDepositRecord()
            setLoading(() => false)
        }
        else {
            setLoading(() => false)
            toast({ type: 'error', message: res.message })
        }

        setLoading(() => false)
    }

    return (
        <div className='h-full'>
            {/* <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>Deposit</h4>
            </div> */}

            <div className='flex justify-between items-start gap-8 flex-col lg:flex-row my-5'>


                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>Deposit Information</p>
                        </div>
                        <div>
                            {/* <p className='font-pop text-sm md:text-base capitalize' style={{ fontWeight: 400, color: 'var(--T, #313131)' }}>[{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}]</p> */}
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={onSubTransDeposit} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Enter Amount *</label><br />
                            <input className='text-sm md:text-base' type='number' name='enterAmount' required />
                            <br />
                            <label className='text-sm md:text-base'>Transaction number *</label><br />
                            <input className='text-sm md:text-base' type='text' name='transactionNumber' required /><br />
                            <label className='text-sm md:text-base'>Upload screenshot *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base pr-20' type='text' value={ss} readOnly onClick={(() => handleLabelClick1())} />
                                <input
                                    type="file"
                                    id="img"
                                    name='attachment'
                                    ref={fileInputRef1}
                                    accept="image/*"
                                    onChange={selectFile}
                                    required
                                    style={{ display: "none", marginBottom: '0px' }}
                                />
                                <span className='absolute bottom-8 md:bottom-7 right-2 text-sm md:text-base cursor-pointer' onClick={(() => handleLabelClick1())} style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 14.9991V3.61914" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span>
                            </div>
                            {previewImg &&
                                <div className='mb-2 mt-1'>
                                    <Badge badgeContent={<ClearIcon fontSize='small' />} color="error" sx={{ cursor: 'pointer' }} onClick={() => { clearPreview1() }}>
                                        <img src={previewImg} alt='' className='img-fluid' width={100} />
                                    </Badge> <br />
                                </div>
                            }
                            <button type='submit' className='btn-theme-color text-base' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                <span className="pl-3">Loading...</span></> : 'Submit'}</button>
                        </form>
                    </div>
                </div>

                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>The amount would be transferred to</p>
                        </div>
                        <div>
                            {/* <p className='font-pop text-sm md:text-base capitalize' style={{ fontWeight: 400, color: 'var(--T, #313131)' }}>[{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}]</p> */}
                        </div>
                    </div>

                    <div className='px-3 pt-5 pb-2'>
                        <div>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Account Name :</span> {bankDetails?.accountName}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Account Number :</span> {bankDetails?.accountNumber}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Bank Name :</span> {bankDetails?.bankName}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>IFSC Code :</span> {bankDetails?.swiftIFSCCode}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Type :</span> {bankDetails?.type}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Account Type :</span> {bankDetails?.accountType}
                            </p>
                            <p className='font-pop font-normal pt-2' style={{ color: '#313131' }}>
                                <li>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this account.</li>
                                <li>UPI Apps like gPay, PhonePe, Paytm are available.</li>
                                <li>INR Deposit and Withdrawal take upto 24 hours to Process.</li>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <p className='font-pop text-lg md:text-xl relative top-3' style={{ fontWeight: 600, color: '#5A45CE', display: record && record.length > 0 ? '' : 'none' }}>Deposit Record</p>
            <RecordTable data={record} />
        </div>
    );
};

export default Deposit;