import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Contact = () => {
    return (
        <section className='bg-default py-5 flex justify-center items-center h-full' style={{ minHeight: '91vh' }}>
            <div className='container mx-auto px-4 flex justify-center items-center'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='cursor-pointer'>
                        <h4 className='font-pop font-semibold text-lg md:text-2xl flex justify-center items-center' style={{ color: 'black' }}><span className='w-full'><ArrowBackIcon /></span> <span className='w-full relative right-12'>Contact</span></h4>
                    </div>
                    <div className='flex justify-center items-center flex-col pb-5'>
                        <div><img src='/assets/mail.gif' alt='' width={400} /></div>
                        <div>
                            <a className='font-pop font-semibold text-lg md:text-2xl' href='mailto:support@uandco.org'>support@uandco.org</a>
                            {/* <p className='font-pop font-semibold text-lg md:text-2xl'>support@uandco.org</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;