import React from 'react';

const About = () => {
    return (
        <section className='bg-default py-5' style={{ minHeight: '91vh' }}>
            <div className='container mx-auto px-4'>
                <div className='text-center'>
                    <h3 className='font-pop font-semibold text-xl md:text-2xl'>About Us</h3>
                    <h3 className='font-pop font-semibold text-lg md:text-2xl pt-2'>Pioneer of the Affiliate Marketing with 20 years of experience</h3>
                </div>

                <div className='mt-5'>

                    <div className='flex flex-col lg:flex-row justify-between items-center gap-5 w-full'>
                        <div className='w-full'>
                            <img src='/assets/about-1.svg' alt='' />
                        </div>
                        <div className='w-full'>
                            <h3 className='font-pop font-semibold text-xl mb-1'>Vision: </h3>
                            <p className='font-pop text-sm md:text-base'>We believe that every human deserves to have access to the best technology available. We want to be your partner in helping your economic level up.</p>
                            <p className='font-pop text-sm md:text-base my-3'>We are here to build a future where everyone can travel with ease and confidence in a decentralized economy.</p>
                            <p className='font-pop text-sm md:text-base mb-3'>We believe in blockchain technology that is revolutionizing the traditional economy, so our mission is to help both seasoned and inexperienced cryptocurrency enthusiasts traveling with us in their economic development</p>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row-reverse justify-between items-center gap-5 w-full mt-5'>
                        <div className='w-full'>
                            <img src='/assets/about-2.svg' alt='' />
                        </div>
                        <div className='w-full'>
                            <h3 className='font-pop font-semibold text-xl mb-1'>Mission : </h3>
                            <p className='font-pop text-sm md:text-base'>We believe that our platform functions to make our business transactions easily accessible, transparent and secure for all our users.
                            </p>
                            <p className='font-pop text-sm md:text-base my-3'>Our platform empowers users to actively engage in cryptocurrency projects, contribute to affiliate marketing, and easily earn passive income.</p>
                        </div>
                    </div>

                </div>

            </div>

        </section>
    );
};

export default About;