import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';

const Step4 = ({ setStep }) => {
    const [otp, setOtp] = useState('');
    const [seconds, setSeconds] = useState(60);
    useEffect(() => {
        let intervalId;

        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else {
            // setSeconds // Call the function to trigger the OTP resend action
        }

        return () => clearInterval(intervalId); // Clean up the interval on unmount

    }, [seconds]);
    const handleKeyPress = async (e) => {
        // console.log('E', e);
        if (e.key === 'Enter') {
            // Call your function here
            // await verifyingOTP(otp, props);
        }
    };
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up3.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={e => { e.preventDefault(); setStep(5) }} className='form-inputs mx-auto px-3 md:px-8'>
                    <label className='text-sm md:text-base'>Enter Verification Number</label><br />
                    <div className='mx-auto flex justify-center items-center w-full'>
                     <div>
                     <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType="number"
                            shouldAutoFocus={true}
                            containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center", overflow: 'hidden' }}
                            inputStyle={{ borderRadius: "15px", border: "1px solid rgba(0, 0, 0, 0.10)", width: window.innerWidth < 500 ? "30px" : "50px", height: window.innerWidth < 500 ? "30px" : "50px", outline: "none", fontWeight: "400", fontSize: "16px", fontFamily: '"Poppins", sans-serif', textAlign: "center", background: "#F3F2FD", color: "var(--T, #313131)" }}
                            renderSeparator={<span className='mx-2'></span>}
                            renderInput={(props) => <input {...props} onKeyDown={handleKeyPress}/>}
                        />
                        {/* <p className='text-end text-danger pt-3 fw-bolder' style={{ fontFamily: "Open Sans", cursor: 'pointer' }} onClick={() => resendOTPIssue()}>Resend OTP{seconds > 0 ? ` (${seconds})` : ""}</p> */}
                     </div>
                    </div>
                    <button type='submit' className='btn-theme-color text-base md:text-lg'>Next</button>
                </form>
            </div>
        </div>
    );
};

export default Step4;