import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalContextData } from '../../core/context/AdminContext/GlobalContext';
import Loader from '../Loader/Loader';

const ProtectedRoute = ({ children }) => {
    let location = useLocation();
    const { user, loading } = useGlobalContextData();
    if (loading) {
        return <Loader />
    }

    if (user && (user?.email) && (user?.kyc?.status == 3) && (user?.bankDetails?.status == 3)) {
        return children
    }
    if (user == null || !user) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    if (user && (!user?.steps?.step4)) {
        return <Navigate to="/sign-up" state={{ from: location }} />;
    }
    if (user && (user?.steps?.step4) && ((user?.kyc?.status != 3) || (user?.bankDetails?.status != 3))) {
        return <Navigate to="/kyc-bank" state={{ from: location }} />;
    }
    return <Navigate to="/" state={{ from: location }} />;
};

export default ProtectedRoute;