import React, { useMemo } from 'react';
import './Flow.css';
import '@xyflow/react/dist/style.css';
import { ReactFlow, Handle, Position } from '@xyflow/react';
import { useGlobalContextData } from '../../../../../core/context/AdminContext/GlobalContext';

const CustomNode = ({ data }) => {
    return (
        <>
            <Handle type="target" position={Position.Top} />
            <div style={{ position: 'relative', textAlign: 'center', minWidth: '125px', minHeight: '45px' }}>
                {data?.badge && (
                    <div style={{
                        position: 'absolute',
                        top: '-48%',
                        right: '27%',
                        display: 'inline-flex',
                        background: '#FFE3E2',
                        padding: '5px 13px',
                        borderRadius: '25px',
                        fontSize: '10px',
                        color: '#F10D0D',
                        fontFamily: '"Poppins", sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        // opacity: 0.75,
                    }}>
                        {data?.badge}
                    </div>
                )}
                <div style={{ position: 'relative', top: '10px' }}>
                    {data?.label}
                </div>
                {/* <Handle type="source" position={Position.Bottom} id="a" style={{ position: 'relative', bottom: '-17px' }} /> */}
                {/* <Handle
                    type="source"
                    position={Position.Bottom}
                    id="b"
                    style={{ position: 'relative', bottom: '-10px' }}
                /> */}
            </div >
        </>
    );
};

const edges = [
    {
        id: '1-2',
        source: '1',
        target: '2',
        type: 'step',
        animated: true,
        style: { stroke: '#304CCF', strokeWidth: 2 },
    },
    {
        id: '1-3',
        source: '1',
        target: '3',
        type: 'step',
        animated: true,
        style: { stroke: '#304CCF', strokeWidth: 2 },
    }
];


function Flow({ getReferralStake }) {
    const { user } = useGlobalContextData()
    // console.log('getUserStake', getReferralStake)
    const nodes = [
        {
            id: '1',
            data: {
                label: (
                    <div>
                        <p className='font-pop text-xs' style={{ color: user?.isStake ? 'white' : 'black', fontWeight: user?.isStake  ? 600 : 400 }}>{user?.firstName}</p>
                        <p className='font-pop' style={{ fontSize: '10px', color: user?.isStake ? 'white' : 'black', fontWeight: user?.isStake ? 600 : 400 }}>Head</p>
                    </div>
                ),
            },
            position: { x: 0, y: 0 },
            type: 'input',
            draggable: false,
            selectable: false,
            style: { backgroundColor: user?.isStake ? "#26BC00" : 'white' }
        },
        {
            id: '2',
            data: {
                label: (
                    <div>
                        {
                            (getReferralStake && getReferralStake.length > 0 && getReferralStake[0]) ? <>
                                <p className='font-pop text-xs' style={{ color: 'white', fontWeight: 600 }}>{getReferralStake[0]?.firstName}</p>
                                <p className='font-pop' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>User A</p></> :
                                <p className='font-pop text-xs' style={{ color: 'black' }}>No Referral</p>
                        }

                    </div>
                ),
                badge: 'Level 1'
            },
            position: { x: -100, y: 125 },
            type: 'custom',
            draggable: false,
            selectable: false,
            style: { backgroundColor: (getReferralStake && getReferralStake.length > 0 && getReferralStake[0]) ? getReferralStake[0]?.isStake ? '#26BC00' : '#FCB527' : 'white' }
        },
        {
            id: '3',
            data: {
                label: (
                    <div>
                        {
                            (getReferralStake && getReferralStake.length > 0 && getReferralStake[1]) ? <>
                                <p className='font-pop text-xs' style={{ color: 'white', fontWeight: 600 }}>{getReferralStake[1]?.firstName}</p>
                                <p className='font-pop' style={{ fontSize: '10px', color: 'white', fontWeight: 600 }}>User B</p></> :
                                <p className='font-pop text-xs' style={{ color: 'black' }}>No Referral</p>
                        }
                    </div>
                ),
                badge: 'Level 1'
            },
            position: { x: 100, y: 125 },
            type: 'custom',
            draggable: false,
            selectable: false,
            style: { backgroundColor: (getReferralStake && getReferralStake.length > 0 && getReferralStake[1]) ? getReferralStake[1]?.isStake ? '#26BC00' : '#FCB527' : 'white' }
        },
    ];

    const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);
    return (
        <div style={{ height: '300px', width: '100%' }}>
            <ReactFlow
                nodeTypes={nodeTypes}
                style={{ height: '100%', width: '100%' }}
                fitView={true}
                minZoom={0.5}
                maxZoom={1.3}
                nodes={nodes}
                edges={edges}
                elementsSelectable={false}
                nodesDraggable={false}
                nodesConnectable={false}
                zoomOnScroll={false}
                zoomOnPinch={false}
                panOnScroll={false}
                panOnDrag={false}
                zoomOnDoubleClick={false}
                preventScrolling={false}
            />
        </div>
    );
}

export default Flow;
