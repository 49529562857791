import React from 'react';
import './Footer.css'
import NewsLetter from './NewsLetter';
import { useNavigate } from 'react-router-dom';

const data = [
    { name: 'Home', path: '/home' },
    // { name: 'Investment' },
    { name: 'About', path: '/about' },
    { name: 'Contact', path: '/contact' },
]

const Footer = () => {
    const navigate = useNavigate();
    return (
        <section className='py-7 bg-footer text-white'>
            <div className='container mx-auto px-4'>

                <div className='flex flex-col md:flex-row justify-between items-center' style={
                    { gap: window.innerWidth <= 768 ? '15px' : '' }
                }>
                    <div className='w-full'>
                        {/* <span className="self-center whitespace-nowrap text-xl md:text-2xl font-bold text-white header-nav-logo">Logo</span> */}
                        <img src="/assets/logo.png" className="self-center whitespace-nowrap text-xl md:text-2xl font-bold text-white header-nav-logo" width={180} alt="Flowbite React Logo" />
                    </div>
                    <div className='w-full'>
                        <div className='flex flex-col sm:flex-row justify-start items-start sm:justify-between sm:items-center' style={
                            { gap: window.innerWidth <= 768 ? '15px' : '' }
                        }>
                            <div>
                                <ul class="font-medium footerLinks">
                                    {
                                        data.map((el, index) => <li key={index} className='cursor-pointer' onClick={() => navigate(el?.path)}>
                                            {el?.name}
                                        </li>)
                                    }

                                </ul>
                            </div>
                            <div>
                                <ul class="font-medium footerLinks">
                                    <li >
                                        Support
                                    </li>
                                    <li >
                                        <div className='w-56  md:w-60  lg:w-80  2xl:w-96  items-center'>
                                            support@uandco.org
                                        </div>
                                        {/* <NewsLetter /> */}
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Footer;