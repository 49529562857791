import React from 'react';

const DHomeUpperCards = ({ data }) => {
    const { title = '', desc = '', id = 0 } = data
    return (
        <div className='dHomeUpperCard common-transition'>
            <h5 className='text-sm lg:text-base w-full'>{title}</h5>
            <p className='text-base lg:text-lg'>{id == 3 ? '' : `₹`}{desc}</p>
        </div>
    );
};

export default DHomeUpperCards;