import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Config from '../../../core/config';
import { makeRequestWihActualStatus } from '../../../core/services/v1/request';
import { toast } from '../../../core/lib/toastAlert';
import { useGlobalContextData } from '../../../core/context/AdminContext/GlobalContext';
import { Spinner } from 'flowbite-react';
import { RegistrationSuccess } from '../../../Components/Modals/RegistrationSuccess';

const Step6 = ({ user, setUser, step }) => {
    const [openModal, setOpenModal] = useState(false);
    const { getTheCurrent } = useGlobalContextData();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (user?.steps?.step4) {
            return navigate('/kyc-bank')
        }
    }, [user, step])

    const onSubStep4 = async (e) => {
        e.preventDefault();
        setLoading(() => true);
        if (!e.target.checked.checked) {
            setLoading(() => false);
            return toast({ type: 'error', message: 'Please Agree Terms and Privacy Policies' })
        }
        const data = {
            addressNo: e.target.addressNo.value,
            state: e.target.state.value,
            city: e.target.city.value,
            zip: e.target.zip.value,
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/sign-up?step=4`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            setOpenModal(() => true)
            getTheCurrent();
            // setUser((data) => { return { ...data, steps: { ...data?.steps, step4: true } } })
            // toast({ type: 'success', message: res.message })
            e.target.reset();
            setLoading(() => false);
            return navigate('/kyc-bank')
        }
        else {
            setLoading(() => false);
            toast({ type: 'error', message: res.message })
        }
        setLoading(() => false);
    }
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up4.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={onSubStep4} className='mx-auto px-3 md:px-8'>
                    <div className='form-inputs'>
                        <label className='text-sm md:text-base'>Enter  Your Address *</label><br />
                        <input className='text-sm md:text-base' type='text' name='addressNo' required /> <br />
                        <label className='text-sm md:text-base'> Enter Your State *</label><br />
                        <input className='text-sm md:text-base' type='text' name='state' required /> <br />
                        <label className='text-sm md:text-base'>Enter Your City *</label><br />
                        <input className='text-sm md:text-base' type='text' name='city' required /> <br />
                        <label className='text-sm md:text-base'>Enter Your Zipcode *</label><br />
                        <input className='text-sm md:text-base' type='text' name='zip' required /> <br />
                    </div>
                    <div class="flex items-start mb-5">
                        <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" name='checked' class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
                        </div>
                        <label for="terms" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300" style={{ fontFamily: '"Poppins", sans-serif' }}>I agree to all the <span style={{ color: '#FF8682', fontWeight: 600, fontFamily: '"Poppins", sans-serif' }} class="hover:underline">Terms</span> and <span style={{ color: '#FF8682', fontWeight: 600, fontFamily: '"Poppins", sans-serif' }} class="hover:underline">Privacy Policies</span></label>
                    </div>
                    <div className='form-inputs'>
                        <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                            <span className="pl-3">Loading...</span></> : 'Register'}</button>
                    </div>
                </form>
            </div>
            {openModal ? <RegistrationSuccess openModal={openModal} setOpenModal={setOpenModal} /> : null}
        </div>
    );
};

export default Step6;