"use client";
import React from 'react';
import { Spinner, Table } from "flowbite-react";
import { dateFormat } from '../../../../core/helper/date-format';

const EasyHistory = ({ data, loading }) => {
    // console.log(data)
    return (
        <div className='flex justify-center items-center mb-5 mt-12'>
            {(data && data.length > 0) &&
                <div className='w-full'>
                    <h4 className='font-pop font-semibold text-lg md:text-xl mb-3' style={{ color: 'black' }}>History</h4>
                    <div className='bg-white w-full px-3 py-4' style={{ borderRadius: '16px' }}>
                        <div className="overflow-x-auto">
                            <Table className='table-fixed-custom'>
                                <Table.Head>
                                    <Table.HeadCell className='table-cell-width'>S No</Table.HeadCell>
                                    <Table.HeadCell className='table-cell-width'>Date</Table.HeadCell>
                                    <Table.HeadCell className='table-cell-width'>Plan</Table.HeadCell>
                                    <Table.HeadCell className='table-cell-width'>Unitic</Table.HeadCell>
                                    <Table.HeadCell className='table-cell-width'>Price</Table.HeadCell>
                                    <Table.HeadCell className='table-cell-width'>Status</Table.HeadCell>
                                </Table.Head>
                                <Table.Body className="divide-y">
                                    {(loading && !data) && <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="table-cell-width">
                                            <Spinner aria-label="Center-aligned spinner example" size={'lg'} />
                                        </Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                    </Table.Row>}

                                    {data && !loading && data.map((el, index) => {
                                        const { amount, status, createdAt, price, required } = el;

                                        return (
                                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" id={index}>
                                                <Table.Cell>
                                                    {index + 1}
                                                </Table.Cell>
                                                <Table.Cell className='table-cell-width'>{dateFormat(createdAt)}</Table.Cell>
                                                <Table.Cell className='table-cell-width'>{amount}</Table.Cell>
                                                <Table.Cell className='table-cell-width'>{required}</Table.Cell>
                                                <Table.Cell className='table-cell-width'>{price} INR</Table.Cell>
                                                <Table.Cell className='table-cell-width'>Successful</Table.Cell>
                                            </Table.Row>)
                                    }
                                    )}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default EasyHistory;