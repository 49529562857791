"use client";
import { Button, Checkbox, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import Config from "../../../core/config";
import {  makeRequestWithErrorMessage } from "../../../core/services/v1/request";
import { toast } from "../../../core/lib/toastAlert";
import { useGlobalContextData } from "../../../core/context/AdminContext/GlobalContext";
import { deleteCookie } from "../../../core/helper/cookie";

export default function VerifyPopUp({ sendOTP, openModal, setOpenModal, loading, email,user,setUser,onSubOTP }) {
    const [otp, setOtp] = useState('');
    const { seconds } = useGlobalContextData()
    const handleKeyPress = async (e) => {
        // console.log('E', e);
        if (e.key === 'Enter') {
            // Call your function here
            // await verifyingOTP(otp, props);
        }
    };
    function onCloseModal() {
        setOpenModal(false);
    }

    return (
        <>
            <Modal show={openModal} size="md" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <form onSubmit={(e)=>onSubOTP(e,otp)} className='form-inputs mx-auto'>
                        <label className='text-sm md:text-base'>Email</label><br />
                        <input className='text-sm md:text-base' style={{ height: '40px' }} name='email' type='text' value={email} disabled required /> <br />
                        <label className='text-sm md:text-base'>OTP *</label>
                        {/* <input className='text-sm md:text-base' style={{height:'40px'}} name='referralId' type='text' /> <br /> */}
                        <div className='mx-auto flex justify-center items-center w-full'>
                            <div>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputType="number"
                                    shouldAutoFocus={true}
                                    containerStyle={{ width: "100%", marginLeft: "auto", marginRight: "auto", textAlign: "center", overflow: 'hidden' }}
                                    inputStyle={{ borderRadius: "15px", border: "1px solid rgba(0, 0, 0, 0.10)", width: window.innerWidth < 500 ? "30px" : "45px", height: window.innerWidth < 500 ? "30px" : "45px", outline: "none", fontWeight: "400", fontSize: "16px", fontFamily: '"Poppins", sans-serif', textAlign: "center", background: "#F3F2FD", color: "var(--T, #313131)" }}
                                    renderSeparator={<span className='mx-2'></span>}
                                    renderInput={(props) => <input {...props} onKeyDown={handleKeyPress} />}
                                />
                                <p className='text-end text-red-600 text-sm font-semibold pb-2 font-pop-colorLess' style={{ cursor: 'pointer' }} onClick={() => {
                                    if (seconds <= 0) {
                                        sendOTP()
                                    }
                                }}>Resend OTP{seconds > 0 ? ` (${seconds})` : ""}</p>
                            </div>
                        </div>
                        <button type='submit' className='btn-theme-color text-base' style={{ height: '40px' }} disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                            <span className="pl-3">Loading...</span></> : 'Verify'}</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
