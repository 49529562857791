import React from 'react';
import { useNavigate } from 'react-router-dom';

const UAndCoPlan = () => {
    const navigate = useNavigate();
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>U&CO Plan</h4>
            </div>

            <div className='flex flex-col md:flex-row justify-center items-center w-full pt-5 gap-y-8 gap-x-6 lg:gap-x-0'>
                <div className='w-full flex justify-center items-center'>
                    <div className='bg-white w-auto md:w-7/12 lg:w-6/12 xl:w-4/12'>
                        <div className='plan-header-1'>
                            <h4 className='uppercase text-base md:text-lg'>Easy Money Tree Plan</h4>
                        </div>

                        <div className='pt-0 pb-2'>
                            <img src='/assets/level1.svg' alt='' />
                        </div>

                        <div className='px-3'>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#7363EC" stroke="#7363EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>
                                        <span className='font-bold'>Level1 (Founder Circle): </span>Earn From Personal Sales and A Percentage of sales from Level2 and Level3 downlines
                                    </p>
                                </div>
                            </div>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#7363EC" stroke="#7363EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>All Earning are paid in crypto currency</p>
                                </div>
                            </div>
                        </div>

                        <div className='pt-0 pb-3'>
                            <img src='/assets/investment.svg' alt='' />
                        </div>

                        <div className='px-3'>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#7363EC" stroke="#7363EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>
                                        The <span className='font-bold'>Level1 Investment of 3.5L</span> will Give an <span className='font-bold'>outcome of 5L</span> only after joining two Downlines under user within 365 days
                                    </p>
                                </div>
                            </div>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#7363EC" stroke="#7363EC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>If thw User Didn’t Complete or withdraw to the admin within 365 days then
                                        the Initial Investment of 3.5L will be added with <span className='font-bold'> 12% Interest on Annual basic & Return to Him after 365</span> within 15 days of time period</p>
                                </div>
                            </div>

                            <p className='text-center pt-0 pb-2  font-pop font-normal' style={{ color: '#313131' }}>Terms & Condition</p>
                        </div>

                        <div className='plan-footer-1' onClick={()=> navigate('/dashboard/uandco/plan-1')}>
                            <p>select this Plan</p>
                        </div>
                    </div>

                </div>

                {/* <div className='w-full flex justify-center items-center'>
                    <div className='bg-neutral-200 w-auto lg:w-3/4'>
                        <div className='plan-header-2'>
                            <h4 className='uppercase text-base md:text-lg'>Easy Money Tree Plan</h4>
                        </div>

                        <div className='pt-0 pb-2'>
                            <img src='/assets/level1.svg' alt='' />
                        </div>

                        <div className='px-3'>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#FCB527" stroke="#FCB527" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>
                                        <span className='font-bold'>Level1 (Founder Circle):</span>Earn From Personal Sales and A Percentage of sales from Level2 and Level3 downlines
                                    </p>
                                </div>
                            </div>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#FCB527" stroke="#FCB527" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>All Earning are paid in crypto currency</p>
                                </div>
                            </div>
                        </div>

                        <div className='pt-0 pb-2'>
                            <img src='/assets/investment.svg' alt='' />
                        </div>

                        <div className='px-3'>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#FCB527" stroke="#FCB527" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>
                                        The <span className='font-bold'>Level1 Investment of 3.5L</span> will Give an <span className='font-bold'>outcome of 5L</span> only after joining two Downlines under user within 365 days
                                    </p>
                                </div>
                            </div>
                            <div className='flex justify-start items-center pb-4' style={{ gap: '7px' }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#FCB527" stroke="#FCB527" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M16.125 9.75L10.6219 15L7.875 12.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className='font-pop font-normal' style={{ color: '#313131' }}>If thw User Didn’t Complete or withdraw to the admin within 365 days then
                                        the Initial Investment of 3.5L will be added with <span className='font-bold'> 12% Interest on Annual basic & Return to Him after 365</span> within 15 days of time period</p>
                                </div>
                            </div>

                            <p className='text-center pt-0 pb-2 font-pop font-normal' style={{ color: '#313131' }}>Terms & Condition</p>
                        </div>

                        <div className='plan-footer-disabled'>
                            <p>Coming Soon</p>
                        </div>
                    </div>

                </div> */}
            </div>
        </div>
    );
};

export default UAndCoPlan;