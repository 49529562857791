import React from 'react';
import './HowToStake.css';

const data = [
    { img: "/assets/point_1.svg", title: "Connect Wallet", desc: "Easily connect your wallet via WalletConnect to start." },
    { img: "/assets/point_2.svg", title: "Register Details", desc: "Create your account with basic information to get started." },
    { img: "/assets/point_3.svg", title: "Buy Crypto", desc: "Purchase the cryptocurrency to stake directly on platform." },
    { img: "/assets/point_4.svg", title: "Deposit & Stake", desc: "Choose staking plan , Deposit & Start staking." },
]

const HowToStake = () => {
    return (
        <section className='py-7 bg-sub-home'>
            <div className='container mx-auto px-4'>
                <div>
                    <h1 className='text-center text-2xl md:text-4xl home-title'>How to Stake on U&Co.</h1>
                    <div className='flex justify-center items-center'>
                        <p className='text-xs md:text-sm text-justify home-sub-title pt-2'>Build & Scale your Project with our most Compressive set of<br />web3 Tools .We Provide wallets, markets, NFT tools and more</p>
                    </div>
                </div>
                <div className='pt-10 flex flex-col-reverse md:flex-row justify-between items-center'>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                            <img src='/assets/stakeBitcoin.svg' alt='' className='img-fluid' width={500} />
                        </div>
                    </div>
                    <div className='w-full'>
                        {
                            data.map((el, index) => <div key={index} className={`flex justify-start items-start mb-10`} style={{ gap: '10px' }}>
                                <div>
                                    <img src={el?.img} alt='' className='img-fluid' height={34} width={34}/>
                                </div>
                                <div className='w-full'>
                                    <h3 className='how-stake-title text-xl'>{el?.title}</h3>
                                    <p className='how-stake-sub-title text-sm'>{el?.desc}</p>
                                </div>
                            </div>)
                        }

                        <div className='flex justify-start items-center' style={{ gap: '15px' }}>
                            <div>
                                <h3 className='how-stake-title text-2xl'>350+</h3>
                                <p className='how-stake-sub-title text-base'>Crypto Currencies</p>
                            </div>
                            <div>
                                <h3 className='how-stake-title text-2xl'>100000+</h3>
                                <p className='how-stake-sub-title text-base'>Crypto Currencies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default HowToStake;