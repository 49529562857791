import React, { useEffect, useState } from 'react';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { Spinner } from 'flowbite-react';
import Config from '../../../../core/config';
import { makeRequestWihActualStatus, makeRequestWithErrorMessage } from '../../../../core/services/v1/request';
import { toast } from '../../../../core/lib/toastAlert';
import swal from "sweetalert";
import EasyHistory from './EasyHistory';

const UAndCoPlanDetails = () => {
    const { pairDetails, metamaskBalance, getBalanceTestnet, metamaskBalanceLoading, planAmount1, getTheCurrent, transferUniticWithArbirum, user } = useGlobalContextData();
    const [loading, setLoading] = useState(false)
    const [history, setHistoryData] = useState([]);
    const [loading2, setLoading2] = useState(false);

    const getHistoryEasy = async () => {

        setLoading2(() => true)
        const params = {
            url: `${Config.V1_API_URL}stake-trade/get-stake-user`,
            method: 'GET',
        }

        const response = (await makeRequestWihActualStatus(params));

        if (response.status) {
            setHistoryData(() => response?.data)
            setLoading2(() => false)
        }
        else {
            setLoading2(() => false)
        }
        setLoading2(() => false)
    }

    useEffect(() => {
        getHistoryEasy();
        getBalanceTestnet()
    }, [])
    // console.log(metamaskBalance)
    const submitStakeTrade = async (name = 'Easy') => {
        setLoading(() => true)

        const params = {
            url: `${Config.V1_API_URL}stake-trade/verify-stake`,
            method: 'GET',
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            let transferAmount = (planAmount1 / pairDetails?.price).toFixed(2)
            transferAmount = (+transferAmount)

            if (metamaskBalance?.unitic < transferAmount) {
                setLoading(() => false);
                return toast({ type: 'error', message: 'Insufficient Balance' })
            }

            const transfer = await transferUniticWithArbirum(transferAmount)

            if (transfer) {
                const data = {
                    name: name,
                    amount: planAmount1,
                    price: pairDetails?.price,
                    balance: metamaskBalance?.unitic,
                    required: (planAmount1 / pairDetails?.price).toFixed(2),
                }
                const params = {
                    url: `${Config.V1_API_URL}stake-trade/create-stake`,
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'Application/json',
                    },
                }
                const res = await makeRequestWithErrorMessage(params)
                if (res.status) {
                    getTheCurrent();
                    // toast({ type: 'success', message: res.message })
                    setLoading(() => false);
                    const wrapper = document.createElement("div");
                    wrapper.innerHTML = `
                    <p class="text-success">Stake Started Successful.</p>
                    <a href=${transfer} target="_any" class="link_hash text-decoration-none break-all">Mainnet Link: <span class="text-blue-600 break-all">${transfer}</span></a>`;
                    return swal({
                        title: "Transferred",
                        content: wrapper,
                        icon: "success",
                        button: "Close",
                        className: "modal_class_success",
                    });

                }
                else {
                    setLoading(() => false);
                    return swal({
                        title: "Attention",
                        content: res.message,
                        icon: "warning",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                    });
                }
                setLoading(() => false);
            }
            else {
                setLoading(() => false)
                toast({ type: 'error', message: 'Failed To Stake' })
            }
        }
        else {
            setLoading(() => false)
            toast({ type: 'error', message: res.message })
        }

        setLoading(() => false);
    }
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>U&CO Plan Details</h4>
            </div>
            <div className='flex flex-col md:flex-row justify-center items-center w-full pt-7 gap-y-8 gap-x-6 lg:gap-x-0'>
                <div className='w-full flex justify-center items-center'>
                    <div className='bg-white  w-full md:w-3/4 lg:md:w-1/2 xl:w-1/3 px-3 py-4'>
                        <div className='plan-header-1'>
                            <h4 className='uppercase text-base'>Easy Money Tree Plan</h4>
                        </div>

                        <div className='px-3 py-7'>
                            <div>
                                <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                    <span className='font-bold'>Plan Amount :</span> {planAmount1} RS
                                </p>
                                <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                    <span className='font-bold'>Unitic Price :</span> {pairDetails?.price} RS
                                </p>
                                <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                    <span className='font-bold'>Wallet Balance :</span> {metamaskBalanceLoading ? <Spinner aria-label="Spinner button example" size="sm" /> : metamaskBalance.unitic} UNITIC
                                </p>
                                <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                    <span className='font-bold'>Required :</span> {(planAmount1 / pairDetails?.price).toFixed(2)} UNITIC
                                </p>
                            </div>
                        </div>

                        <div className='flex justify-center items-center'>
                            <div className={`plan-footer-1 w-fit ${(loading || metamaskBalanceLoading || user?.isStake) ? ' disabled-plan-1 ' : ''}`} onClick={() => {
                                if (!loading && !metamaskBalanceLoading && !user?.isStake) {
                                    submitStakeTrade()
                                }
                            }} style={{ padding: '7px 30px', borderRadius: '16px', background: loading ? '#1f9c00' : '', cursor: loading ? 'progress' : 'pointer' }}>
                                <p className='text-sm'>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                    <span className="pl-3">Loading...</span></> : 'Stake'}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div>
                <EasyHistory data={history} loading={loading2} />
            </div>
        </div>
    );
};

export default UAndCoPlanDetails;