import React from 'react';

const Step5 = ({ setStep }) => {
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up3.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={e => { e.preventDefault(); setStep(6) }} className='form-inputs mx-auto px-3 md:px-8'>
                    <label className='text-sm md:text-base'>Enter Verification Number</label><br />
                    <input className='text-sm md:text-base' type='number' /> <br />
                    <label className='text-sm md:text-base'>Enter Email</label><br />
                    <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='email' />
                    <span className='absolute bottom-8 md:bottom-7 right-5 text-sm md:text-base cursor-pointer' style={{zIndex:500, color:'#1A8300', fontFamily: '"Poppins", sans-serif'}}>Verified</span>
                    </div>
                    <button type='submit' className='btn-theme-color text-base md:text-lg'>Next</button>
                </form>
            </div>
        </div>
    );
};

export default Step5;