import React, { useEffect, useState } from 'react';
import Config from '../../../core/config';
import { makeRequestWihActualStatus, makeRequestWithErrorMessage } from '../../../core/services/v1/request';
import { toast } from '../../../core/lib/toastAlert';
import { Spinner } from 'flowbite-react';
import PhoneInput from 'react-phone-input-2'
import VerifyPopUp from './VerifyPopUp';
import { useGlobalContextData } from '../../../core/context/AdminContext/GlobalContext';
import { deleteCookie, setCookie } from '../../../core/helper/cookie';
import { useNavigate } from 'react-router-dom';

const Step2 = ({ user, step, setStep, setUser }) => {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneNum, setPhoneNum] = useState(null);
    const { seconds, setSeconds, getTheCurrent } = useGlobalContextData();
    const navigate = useNavigate();
    useEffect(() => {
        let intervalId;

        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else {

        }

        return () => clearInterval(intervalId);

    }, [seconds]);

    useEffect(() => {
        if (user?.steps?.step2) {
            return setStep(() => 3)
        }
    }, [user, step])
    const onSubStep2 = async (e) => {
        e.preventDefault();
        if (!user?.isVerifiedEmail) {
            return openMailveify()
        }
        if (!phoneNum) {
            return toast({ type: 'error', message: 'Please fill the phone number' })
        }
        setLoading(() => true);
        const data = {
            phoneNo: '+' + phoneNum,
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/sign-up?step=2`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            if (res?.isRedirect) {
                await getTheCurrent();
                return navigate('/dashboard')
            }
            else {
                setUser((data) => { return { ...data, steps: { ...data?.steps, step2: true } } })
                setStep(() => 3)
                // toast({ type: 'success', message: res.message })
            }
            e.target.reset();
            setLoading(() => false);
        }
        else {
            setLoading(() => false);
            toast({ type: 'error', message: res.message })
        }
        setLoading(() => false);
    }
    const sendOTP = async () => {
        const data = {
            id: user?._id,
            email: email
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/sending-otp`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            setCookie("otpExpiration", res.data.token);
            toast({ type: 'success', message: res.message })
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    const openMailveify = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            return toast({ type: 'error', message: 'Invalid Email' })
        }
        sendOTP()
        setSeconds(() => 60)
        setOpenModal(() => true)
    }
    const onSubOTP = async (e, otp) => {
        e.preventDefault();
        const data = {
            id: user?._id,
            otp: otp
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/verify-otp`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            if (res.data?.isVerifiedEmail) {
                setUser(() => res.data?.user)
            }
            deleteCookie("otpExpiration");
            toast({ type: 'success', message: res.message })
            setOpenModal(() => false)
        }
        else {
            toast({ type: 'error', message: res.message })
        }
    }
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up2.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={onSubStep2} className='form-inputs mx-auto px-3 md:px-8'>
                    <label className='text-sm md:text-base'>Enter Email *</label><br />
                    {/* <input className='text-sm md:text-base' type='email' name='email' required /> <br /> */}
                    {
                        user?.isVerifiedEmail ?
                            <div className='relative'>
                                <input className='text-sm md:text-base pr-16' type='email' value={user?.email} disabled name='email' required />
                                <span className='absolute bottom-8 md:bottom-7 right-3 text-sm md:text-base cursor-pointer' style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}>Verified</span>
                            </div> : <div className='relative'>
                                <input className='text-sm md:text-base pr-16' type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' required />
                                <span className='absolute bottom-8 md:bottom-7 right-3 text-sm md:text-base cursor-pointer' style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }} onClick={() => openMailveify()}>Verify</span>
                            </div>
                    }
                    <label className='text-sm md:text-base'>Enter Phone Number *</label><br />
                    <PhoneInput
                        enableSearch
                        disableSearchIcon
                        searchStyle={{
                            width: '100%', height: '30px', marginLeft: '0px'
                        }}
                        inputClass='form-inputs-phone'
                        inputProps={{
                            name: 'phone',
                            required: true,
                        }}
                        containerStyle={{
                            width: '100%', marginBottom: '15px',
                            marginTop: '8px'
                        }}
                        country={'in'}
                        value={phoneNum}
                        onChange={(e) => setPhoneNum(() => e)}
                    />
                    {/* <input className='text-sm md:text-base' type='number' name='phoneno' /> <br /> */}
                    <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                        <span className="pl-3">Loading...</span></> : 'Next'}</button>
                </form>
            </div>
            {openModal && <VerifyPopUp openModal={openModal} setOpenModal={setOpenModal} email={email} setUser={setUser} user={user} sendOTP={sendOTP} onSubOTP={onSubOTP} />}
        </div>
    );
};
export default Step2;