"use client";
import React from 'react';
import { Spinner } from "flowbite-react";

const Loader = () => {
    return (
        <div className="flex justify-center items-start pt-10 h-screen">
            <div className="text-center">
                <Spinner aria-label="Center-aligned spinner example" size={'lg'}/>
            </div>

        </div>
    );
};

export default Loader;