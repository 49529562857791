import React from 'react';

const SearchCrypto = () => {
    return (
        <div className='w-56  md:w-60  lg:w-80  2xl:w-96  items-center'>
            <form class="mx-auto" onSubmit={(e)=>{e.preventDefault()}}>
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-9 md:h-10 lg:h-12 2xl:h-14" placeholder="Search Crypto" required style={{ borderRadius: '48px' }} />
                    <button type="submit" class="text-white h-8 md:h-9 lg:h-11 2xl:h-12 absolute end-1 bottom-0.5 2xl:bottom-1 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-4 lg:px-9 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" style={{ borderRadius: '48px', backgroundColor: '#5A45CE' }}>Search</button>
                </div>
            </form>

        </div>
    );
};

export default SearchCrypto;