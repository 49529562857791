import React from 'react';
import './StakingPlatform.css';

const data = [
    { img: "/assets/point_1.svg", title: "Web3", desc: "Witness the potential of finance with U&Co.'s Web3 staking platform. For the best user-centric experience that brings the best of the blockchain revolution to your fingertips." },
    { img: "/assets/point_2.svg", title: "Blockchain", desc: "The power of blockchain technology to offer a secure and transparent staking environment. Our platform ensures your digital assets are protected and your transactions are trustworthy." },
    { img: "/assets/point_3.svg", title: "Staking", desc: "Grow your crypto asset effortlessly with U&Co.'s diverse staking plans. Benefit from competitive rewards and easy access via WalletConnect to support" },
]

const StakingPlatform = () => {
    return (
        <section className='pt-7 bg-sub-home'>
            <div className='container mx-auto px-4'>
                <div>
                    <h1 className='text-center text-2xl md:text-4xl home-title'>Best Web3 Staking Platform</h1>
                    <div className='flex justify-center items-center'>
                        <p className='text-xs md:text-sm text-justify home-sub-title pt-2'>Build & Scale your Project with our most Compressive set of<br />web3 Tools .We Provide wallets, markets, NFT tools and more</p>
                    </div>
                </div>

                <div className='pt-10 flex flex-col-reverse md:flex-row justify-between items-center' style={
                    { gap: window.innerWidth > 768 ? '15px' : '' }
                }>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                            <img src='/assets/stakePlatform.svg' alt='' className='img-fluid' width={500} />
                        </div>
                    </div>
                    <div className='w-full'>
                        {
                            data.map((el, index) => <div key={index} className={`flex justify-start items-start mb-10`} style={{ gap: '10px' }}>
                                <div >
                                    <img src={el?.img} alt='' className='img-fluid' height={34} width={34} />
                                </div>
                                <div className='w-full'>
                                    <h3 className='stake-title text-xl'>{el?.title}</h3>
                                    <p className='stake-sub-title text-sm'>{el?.desc}</p>
                                </div>
                            </div>)
                        }

                    </div>
                </div>
            </div>
        </section>
    );
};

export default StakingPlatform;