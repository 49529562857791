import axios from "axios";
import React, { useEffect, useState, createContext, useContext } from "react";
import { ethers, BigNumber } from "ethers";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";

import {
    USDTtokenAddressTestnet,
    USDTtokenABITest,
    arbitrumAddress,
    ArbitrumABI,
    SmartcontractAddress,
    SmartcontractABI,
    SmartcontractAddressarbitrum,
    SmartcontractarbitrumABI,

} from "../../utils/constant";
import Config from "../../config";
import { useNavigate } from "react-router-dom";
import { deleteCookie, getCookie, setCookie } from "../../helper/cookie";
import { getSiteSettings } from "../helper";
import { makeRequest } from "../../services/v1/request";
import { getUserReferralData } from "../../services/all.api";

const GlobalContext = createContext();

export const useGlobalContextData = () => {
    const context = useContext(GlobalContext);
    // console.log('context----', context)
    if (context === undefined) {
        throw new Error(`Context missing provider`);
    }
    return context;
};

const { ethereum } = window;

const getSmartContractTestnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const SmartUsdtContract = new ethers.Contract(
        SmartcontractAddress,
        SmartcontractABI,
        signer
    );

    return SmartUsdtContract;
};

const getUSDtokenContractTestnet = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
        USDTtokenAddressTestnet,
        USDTtokenABITest,
        signer
    );

    return tokenContract;
};
const getSmartContractArbitrum = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const SmartArbitrumContract = new ethers.Contract(
        SmartcontractAddressarbitrum,
        SmartcontractarbitrumABI,
        signer
    );

    return SmartArbitrumContract;
};

const getArbitrumTokenContract = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
        arbitrumAddress,
        ArbitrumABI,
        signer
    );

    return tokenContract;
};


const getAllItemBlockchain = async () => {
    // const provider = new ethers.providers.JsonRpcProvider(RPC);
    // return {
    //     provider,
    //     deployer: new ethers.Wallet(private_key, provider),
    //     NFTContract: new Contract(mintAddressTestnet, abi, provider),
    // };
};

const genSignature = async (types, voucher, auth) => {
    const domain = {
        name: "NFT-Voucher",
        version: "1",
        verifyingContract: auth.contract,
        // chainId: chainId,
    };
    const BuyNFTVoucher = {
        id: voucher.id,
        price: voucher.price,
        tokenAddress: voucher.tokenAddress,
        nonce: voucher.nonce,
    };

    // const signature = await auth.signer._signTypedData(domain, types, BuyNFTVoucher);

    return {
        ...voucher,
        // signature,
    };
};

const signBuyFunction = async (id, price, tokenAddress, refAddress, uri) => {
    const contracts = await getAllItemBlockchain();
    const auth = {
        signer: contracts.deployer,
        contract: contracts.NFTContract.address,
    };

    const types = {
        BuyNFTStruct: [
            { name: "id", type: "string" },
            { name: "price", type: "uint256" },
            { name: "tokenAddress", type: "address" },
            { name: "nonce", type: "string" },
        ],
    };
    // console.log("111111111111111: ", id, price, tokenAddress, refAddress, uri);

    // Generate nonce as transaction id
    const nonce = uuidv4();
    const voucher = {
        id: id,
        // price: BigNumber.from(price),
        tokenAddress: tokenAddress,
        refAddress:
            refAddress.length !== 0
                ? refAddress
                : "0x0000000000000000000000000000000000000000",
        nonce: nonce,
        uri: uri,
    };
    return {
        ...(await genSignature(types, voucher, auth)),
        price: price.toString(),
    };
};
export default function GlobalProvider({ children }) {

    const [loginModal, setLoginModal] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [requestLoading, setRequestLoading] = useState(false);
    const [walletModal, setWalletModal] = useState(false);
    const [walletWarning, setWalletWarning] = useState(false);
    const [Id, setId] = useState();
    const [userWallet, setUserWallet] = useState({});
    const [pairDetails, setPairDetails] = useState({});
    const [priceDetails, setPriceDetails] = useState({});
    const [loaderPrice, setLoaderPrice] = useState(true);
    const [chain, setChain] = useState("");
    const [payAmount, setPayAmount] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [planAmount1, setPlanAmount1] = useState(350000)
    const [metamaskBalance, setMetamaskBalance] = useState({});
    const [metamaskBalanceLoading, setMetamaskBalanceLoading] = useState(false);
    const [coinbaseModal, setCoinbaseModal] = useState(false);
    const [userRefetch, setUserRefetch] = useState(false);
    const [goToProfile, setGoToProfile] = useState(false);
    const [destination, setDistination] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [withdrawLoading, setWithdrawLoading] = useState(false);
    const [contractData, setContractData] = useState('');
    const [stakeListRefetch, setStakeListRefetch] = useState(false);
    const [walletLoading, setWalletLoading] = useState(true);
    const [siteSettings, setSiteSettings] = useState(null);
    const [getReferralStake, setGetReferralStake] = useState([]);
    const [seconds, setSeconds] = useState(60);
    const navigate = useNavigate()

    const fetchDataArbitrum = async () => {
        try {
            // Connect to the Ethereum network
            const provider = new ethers.providers.Web3Provider(window.ethereum);

            // Load the smart contract's ABI and address
            const contractABI = SmartcontractarbitrumABI; // ABI of your smart contract
            const contractAddress = SmartcontractAddressarbitrum; // Address of your smart contract

            // Instantiate the contract
            const contract = new ethers.Contract(contractAddress, contractABI, provider);
            // console.log(contract);
            // Call a contract function or read a state variable
            const data = await contract.functions.withdraw(); // Replace 'myFunction' with the actual function name

            // Update the component state with the fetched data
            setContractData(() => data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        if (chain == '0xa4b1') {
            fetchDataArbitrum()
        }
    }, [chain]);

    const openWalletModal = () => {
        (!user?.walletAddress ||
            user?.walletAddress === "walletAddress undefined") &&
            setAnchorEl(null);
        setWalletModal(true);
    };
    const closeWalletModal = () => {
        setAnchorEl(null);
        setWalletModal(false);
    }
    const openWalletModalWarning = () => {
        (!user?.walletAddress ||
            user?.walletAddress === "walletAddress undefined") &&
            setAnchorEl(null);
        setWalletWarning(true);
    };
    const closeWalletModalWarning = () => {
        setAnchorEl(null);
        setWalletWarning(false);
    }

    const openCoinbaseModal = () => {
        // (!user?.walletAddress || user?.walletAddress === "undefined") &&
        setCoinbaseModal(true);
    };
    const closeCoinbaseModal = () => setCoinbaseModal(false);

    const openLoginModal = () => setLoginModal(true);
    const closeLoginModal = () => setLoginModal(false);
    const getSiteSettingsState = async () => {
        const siteData = await getSiteSettings();
        setSiteSettings(() => siteData?.message)
    }
    useEffect(() => {
        getSiteSettingsState()
        checkIfWalletIsConnect();
    }, []);

    const getBalanceTestnet = async (defChain = chain) => {
        setMetamaskBalanceLoading(() => true);
        let tokenContract;

        if (defChain == '0xa4b1') {
            tokenContract = getArbitrumTokenContract();
        }
        // else if (defChain == '0x89') {
        //     tokenContract = getUSDtokenContractTestnet();
        // }
        const uniticbalance = await tokenContract?.balanceOf(currentAccount);
        const uniticamount = ethers.utils.formatEther(uniticbalance);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const balance1 = await provider.getBalance(currentAccount);
        // console.log("unitic: " + uniticamount);
        // console.log("BNB: " + ethers.utils.formatEther(balance1));
        const wallet = {
            unitic: uniticamount,
            bnb: ethers.utils.formatEther(balance1),
        };
        setMetamaskBalanceLoading(() => false);
        return setMetamaskBalance(wallet);
    };
    const getUserBalance = async () => {
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'user-wallet/get-wallet' })
        if (res?.status) {
            setUserWallet(() => res.data)
        }
    }
    // const getBalanceArbitrumTestnet = async () => {
    //     const USDtokenContract = getArbitrumTokenContract();
    //     const USDbalance = await USDtokenContract.balanceOf(currentAccount);
    //     const USDamount = ethers.utils.formatEther(USDbalance);
    //     const provider = new ethers.providers.Web3Provider(ethereum);
    //     const balance1 = await provider.getBalance(currentAccount);
    //     console.log("usdt: " + USDamount);
    //     console.log("BNB: " + ethers.utils.formatEther(balance1));
    //     const wallet = {
    //         usdt: USDamount,
    //         bnb: ethers.utils.formatEther(balance1),
    //     };
    //     return setMetamaskBalance(wallet);
    // };

    // useEffect(() => {
    //     getBalanceTestnet();
    // }, []);


    // window.addEventListener("load", function () {
    //     if (window.ethereum) {
    //         // detect Metamask account change
    //         window.ethereum.on("accountsChanged", function (accounts) {
    //             console.log("account is Changed", accounts);
    //             // logOut();
    //             // return swal({
    //             //   title: "Attention",
    //             //   text: "You are being logged out since you changed account. Please login again with the account you need.",
    //             //   icon: "warning",
    //             //   button: "OK",
    //             //   dangerMode: true,
    //             //   className: "modal_class",
    //             // });
    //         });

    //         // detect Network account change
    //         window.ethereum.on("networkChanged", function (networkId) {
    //             console.log("network is changed: ", networkId);
    //             // logOut();
    //             // return swal({
    //             //   title: "Attention",
    //             //   text: "You are being logged out since you Changed network. Please login after changing to Polygon Chain.",
    //             //   icon: "warning",
    //             //   button: "OK",
    //             //   dangerMode: true,
    //             //   className: "modal_class",

    //             // });
    //         });
    //     } else {
    //         throw new Error("No ethereum object");
    //     }
    // });

    const logOut = async () => {
        swal({
            title: "Success",
            text: "You have successfully logged out.",
            icon: "success",
            button: "OK",
            className: "modal_class_success",
        });
        setCurrentAccount(null);
        setUser(null);
        navigate('/')
        deleteCookie("userOfuAndCo");
    };

    const checkIfWalletIsConnect = async () => {
        try {
            if (!ethereum) {
                return console.log("please use metamask");
            }

            const accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length) {
                setCurrentAccount(() => accounts[0]);
                const chainid = await window.ethereum.request({
                    method: "eth_chainId",
                });
                setChain(() => chainid);
            } else {
                console.log("No accounts found");
            }
        } catch (error) {
            console.log(error);
        }
    };
    const transferUniticWithArbirum = async (amount) => {
        try {
            // console.log("amount and affiliate", amount, affiliates);
            if (ethereum) {

                setRequestLoading(true);
                const PGContract = getSmartContractArbitrum();
                const UsdtTokenContract = getArbitrumTokenContract();
                const provider = new ethers.providers.Web3Provider(ethereum);

                // console.log(
                //     "USDT",
                //     PGContract.address,chain,
                //     BigNumber.from(ethers.constants.MaxUint256)
                // );

                const payment = await UsdtTokenContract.approve(
                    PGContract.address,
                    BigNumber.from(ethers.constants.MaxUint256)
                );
                let payment_test = await provider.getTransaction(payment.hash);
                while (payment_test.blockNumber === null) {
                    // console.log("Approve In Progress...");
                    payment_test = await provider.getTransaction(payment.hash);
                }
                // console.log(payment_test.blockNumber);
                let payment_hash = "https://arbiscan.io/tx/" + payment.hash;
                // console.log("Payment link: " + payment_hash);

                const a = ethers.utils.parseEther(amount.toString());
                let affiliate = "0x0000000000000000000000000000000000000000";
                // console.log(typeof affiliate, affiliate);
                const Val = await PGContract.transfer(
                    "0x1D4783444F529614DADA51C063024496bF50f51c",
                    affiliate,
                    a._hex
                );

                await Val.wait();
                let finalPayment = "https://arbiscan.io/tx/" + Val.hash;
                let txn_test = await provider.getTransaction(Val.hash);
                if (txn_test) {
                    while (txn_test.blockNumber === null) {
                        // console.log("Processing...");
                        txn_test = await provider.getTransaction(Val.hash);
                    }
                    setRequestLoading(false);
                    // console.log("txn_test.blockNumber: " + txn_test.blockNumber);
                    return finalPayment;

                }
                setRequestLoading(false);
                return false;

            }
            setRequestLoading(false);
            return false;
        }

        catch (error) {
            console.log(error);
            setRequestLoading(false);
            console.log("No ethereum object");
            //setRequestLoading(false);
            if (error.code === -32603) {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Insufficient funds for transfer!</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });

                return false;
                // Swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            } else {
                const wrapper = document.createElement("div");
                wrapper.innerHTML = `<p class='text-break text-dark fs-5'>Transfer Failed</p>`;
                swal({
                    title: "Attention",
                    content: wrapper,
                    icon: "warning",
                    button: "OK",
                    // dangerMode: true,
                    className: "modal_class_success",
                });
                setRequestLoading(false);
                return false;
                // swal.fire(
                //     {
                //         html: wrapper,
                //         icon: "warning",
                //         customClass: "modal_class_success",
                //     }
                // )
            }
            setRequestLoading(false);
            throw new Error("No ethereum object");
            return false;
        }
    };
    // useEffect(() => {
    //     if (user.walletAddress) {
    //         getBalance();
    //     }
    // }, [user])

    const connectWallet = async (wallet) => {
        try {
            // console.log("connect");
            if (window.innerWidth < 576 && !ethereum) {
                return swal({
                    title: "Attention",
                    text: "Please use Metamask browser!",
                    icon: "warning",
                    button: "OK",
                    dangerMode: true,
                    className: "modal_class",
                });
            }
            if (!ethereum) {
                return console.log("please use metamask");
            }
            if (wallet === "Metamask") {
                setLoading(true);

                const chainid = await window.ethereum.request({
                    method: "eth_chainId",
                });
                // console.log("This is Chain ID: ", chainid);
                setChain(() => chainid);
                if (chainid === "0x89") {
                    const accounts = await ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    setCurrentAccount(() => accounts[0]);
                    // 
                    await axios
                        .post(`${Config.V1_API_URL}wallet-user/`, {
                            walletAddress: accounts[0],
                        })
                        .then((res) => {
                            if (res.data.user) {
                                getBalanceTestnet(chainid);
                                getUserBalance();
                                setUser(res.data.user);
                                setLoading(false);
                                closeWalletModal();
                                setCookie("userOfuAndCo", res.data.token);
                                const wrapper = document.createElement("div");
                                wrapper.innerHTML = `<p class='text-break text-white fs-6'>You have successfully logged in with <br/>Polygon Chain.</p>`;
                                return swal({
                                    // title: "Success",
                                    // text: "You have succesfully logged in with Polygon Chain.",
                                    content: wrapper,
                                    icon: "success",
                                    button: "OK",
                                    // dangerMode: true,
                                    className: "modal_class_success",
                                });
                            }
                        });
                } else {
                    swal({
                        title: "Attention",
                        text: "Please change to Polygon Chain before connecting.",
                        icon: "warning",
                        button: "OK",
                        dangerMode: true,
                        className: "modal_class",
                    });
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            throw new Error("No ethereum object");
        }
    };

    const connectToCoinbase = async () => {
        getBalanceTestnet();
        setAnchorEl(null);
        if (typeof window.ethereum == "undefined") {
            // ask the user to install the extension
            // return swal({
            //     title: "Attention",
            //     text: "Please open this website with wallet browsers",
            //     icon: "warning",
            //     button: "OK",
            //     dangerMode: true,
            //     className: "modal_class",
            // });
            if (window.innerWidth < 1000) {
                // console.log("mobile");

                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/${val}`,
                        "_blank"
                    );
                }
                else {
                    window.open(
                        "https://metamask.app.link/dapp/http://www.utistaking.com/",
                        "_blank"
                    );
                }

            }
            else {
                // console.log("pc");
                window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                );
            }
        }
        let provider = window.ethereum;
        // edge case if MM and CBW are both installed
        if (window.ethereum.providers?.length) {
            window.ethereum.providers.forEach(async (p) => {
                if (p.isCoinbaseWallet) provider = p;
            });
        }
        const chainid = await provider.request({
            method: "eth_chainId",
        });
        // console.log("This is Chain ID: ", chainid);
        setChain(() => chainid);
        // if (chainid === "0x38") {
        const accounts = await provider.request({
            method: "eth_requestAccounts",
        });
        // console.log(accounts[0]);
        setCurrentAccount(() => accounts[0]);

        await axios
            .post(`${Config.V1_API_URL}wallet-user/`, {
                walletAddress: accounts[0],
            })
            .then((res) => {
                if (res.data.user) {
                    getBalanceTestnet(chainid);
                    getUserBalance();
                    setUser(res.data.user);
                    closeCoinbaseModal();
                    setCookie("userOfuAndCo", res.data.token);
                    const wrapper = document.createElement("div");
                    let chainTxt = chainid == '0xa4b1' ? 'Arbitum' : chainid == '0xa4b1' ? '0x89' : ''
                    wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Coin Base ${chainTxt}.</p>`;
                    setLoading(false);
                    return swal({
                        // title: "Success",
                        // text: "You have succesfully logged in with Polygon Chain.",
                        content: wrapper,
                        icon: "success",
                        button: "OK",
                        // dangerMode: true,
                        className: "modal_class_success",
                    });
                }
            });
        setLoading(false);
        // } else {
        //     console.log("Please Switch to Polygon Chain");
        //     swal({
        //         title: "Attention",
        //         text: "Please change to Polygon Chain (Mainnet) before connecting.",
        //         icon: "warning",
        //         button: "OK",
        //         dangerMode: true,
        //         className: "modal_class",
        //     });
        // }
    };


    const connectToTrustWallet = async () => {
        getBalanceTestnet();

        // Check if Trust Wallet is installed
        if (typeof window.ethereum === "undefined") {
            setLoading(true);
            // ask the user to install the wallet or open the app link
            if (window.innerWidth < 1000) {
                // console.log("mobile");

                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(`https://link.trustwallet.com/open_url?coin_id=60&url=https://www.utistaking.com/${val}`, "_blank");
                }
                else {
                    window.open(`https://link.trustwallet.com/open_url?coin_id=60&url=https://www.utistaking.com/`, "_blank");
                }


            } else {
                // console.log("pc");


                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(`https://link.trustwallet.com/browser_open_url?url=https://www.utistaking.com/${val}`, "_blank");
                }
                else {
                    window.open("https://link.trustwallet.com/browser_open_url?url=https://www.utistaking.com/", "_blank");
                }

            }
        }

        let provider = null;

        if (typeof window.ethereum !== "undefined") {
            provider = window.ethereum;

            // edge case if multiple providers are available
            if (window.ethereum.providers?.length) {
                window.ethereum.providers.forEach(async (p) => {
                    if (p.isTrust) provider = p;
                });
            }

            try {
                const accounts = await provider.request({
                    method: "eth_requestAccounts",
                });

                // console.log(accounts[0]);
                setCurrentAccount(() => accounts[0]);
                closeWalletModal();

                await provider.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: "0xa4b1", // Arbitrum One Mainnet chain ID
                            chainName: "Arbitrum One",
                            nativeCurrency: {
                                name: "Ethereum",
                                symbol: "ETH",
                                decimals: 18
                            },
                            rpcUrls: ["https://arb1.arbitrum.io/rpc"], // Arbitrum One Mainnet RPC URL
                            blockExplorerUrls: ["https://arbiscan.io/"] // Arbitrum One Mainnet block explorer URL
                        }
                    ],
                });
                setChain(() => "0xa4b1")

                // getBalanceTestnet("0xa4b1");
                await axios.post(`${Config.V1_API_URL}wallet-user/`, {
                    walletAddress: accounts[0],
                }).then((res) => {
                    if (res.data.user) {
                        setUser(res.data.user);
                        getBalanceTestnet("0xa4b1");
                        getUserBalance();
                        closeWalletModal();
                        setCookie("userOfuAndCo", res.data.token);

                        setLoading(false);
                        const wrapper = document.createElement("div");
                        wrapper.innerHTML = "<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>Trust Wallet Arbitrum Mainnet.</p>";
                        if (res.data?.user && (!res.data?.user?.steps?.step1 || !res.data?.user?.steps?.step2 || !res.data?.user?.steps?.step3 || !res.data?.user?.steps?.step4)) {
                            navigate('/sign-up')
                            return swal({
                                content: wrapper,
                                icon: "success",
                                button: "OK",
                                className: "modal_class_success",
                            }).then((willDelete) => {
                                if (willDelete) {
                                    setDistination(true);
                                    setGoToProfile(true);
                                }
                            });
                        }
                        if (res.data?.user && (!res.data?.user?.kyc?.status != 3)) {
                            navigate('/kyc-bank')
                            return swal({
                                content: wrapper,
                                icon: "success",
                                button: "OK",
                                className: "modal_class_success",
                            }).then((willDelete) => {
                                if (willDelete) {
                                    setDistination(true);
                                    setGoToProfile(true);
                                }
                            });
                        }
                    }
                });
                setLoading(false);
                // console.log("Wallet provider", provider);
                return provider;
            } catch (error) {
                setLoading(false);
                console.log("error ", error)
                throw new Error("User Rejected");
            }
        } else {
            throw new Error("No Wallet found");
        }
    };

    const connectToMetamask = async () => {
        getBalanceTestnet();
        if (typeof window.ethereum == "undefined") {
            setLoading(true);
            // ask the user to install the extension
            // return swal({
            //     title: "Attention",
            //     text: "Please open this website with wallet browsers",
            //     icon: "warning",
            //     button: "OK",
            //     dangerMode: true,
            //     className: "modal_class",
            // });

            if (window.innerWidth < 1000) {
                // console.log("mobile");


                if (sessionStorage.getItem("affiliate")) {

                    let val = sessionStorage.getItem("affiliate")?.split('com/')[1];
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/${val}`,
                        "_blank"
                    );
                }
                else {
                    window.open(
                        `https://metamask.app.link/dapp/http://www.utistaking.com/`,
                        "_blank"
                    );
                }

            }
            else {
                // console.log("pc");
                window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                );
            }
        }
        let provider = null;
        if (typeof window.ethereum !== "undefined") {
            let provider = window.ethereum;
            // edge case if MM and CBW are both installed
            if (window.ethereum.providers?.length) {
                window.ethereum.providers.forEach(async (p) => {
                    if (p.isMetaMask) provider = p;
                });
            }
            try {
                const chainid = await provider.request({
                    method: "eth_chainId",
                });
                // console.log("This is Chain ID: ", chainid);
                setChain(() => chainid);
                if (chainid === "0xa4b1") {
                    const accounts = await provider.request({
                        method: "eth_requestAccounts",
                    });
                    // console.log(accounts[0]);
                    setCurrentAccount(() => accounts[0]);
                    closeWalletModal();

                    await axios
                        .post(`${Config.V1_API_URL}wallet-user`, {
                            walletAddress: accounts[0],
                        })
                        .then((res) => {
                            if (res.data.user) {
                                setUser(res.data.user);
                                // getBalanceMainnet();
                                getBalanceTestnet(chainid);
                                getUserBalance();
                                closeWalletModal();
                                setCookie("userOfuAndCo", res.data.token);
                                const wrapper = document.createElement("div");
                                let networkID = chainid === "0xa4b1" ? "Arbitrum" : "";
                                wrapper.innerHTML = `<p class='text-break text-dark fs-6'>You have successfully logged in with <br/>${networkID}.</p>`;
                                setLoading(false);

                                if (res.data?.user && (!res.data?.user?.steps?.step1 || !res.data?.user?.steps?.step2 || !res.data?.user?.steps?.step3 || !res.data?.user?.steps?.step4)) {
                                    navigate('/sign-up')
                                    return swal({
                                        // title: "Success",
                                        // text: "You have succesfully logged in with Polygon Chain.",
                                        content: wrapper,
                                        icon: "success",
                                        button: "OK",
                                        // dangerMode: true,
                                        className: "modal_class_success",
                                    })
                                        .then((willDelete) => {
                                            if (willDelete) {
                                                setDistination(true);
                                                setGoToProfile(true);
                                            }
                                        })
                                }
                                if (res.data?.user && (!res.data?.user?.kyc?.status != 3)) {
                                    navigate('/kyc-bank')
                                    return swal({
                                        // title: "Success",
                                        // text: "You have succesfully logged in with Polygon Chain.",
                                        content: wrapper,
                                        icon: "success",
                                        button: "OK",
                                        // dangerMode: true,
                                        className: "modal_class_success",
                                    })
                                        .then((willDelete) => {
                                            if (willDelete) {
                                                setDistination(true);
                                                setGoToProfile(true);
                                            }
                                        })
                                }

                            }
                        });
                    // getBalanceMainnet();
                } else {
                    // console.log("Please Switch to Polygon Mainnet or Arbitrum Mainnet");
                    swal({
                        title: "Attention",
                        text: "Please change to Arbitrum Mainnet before connecting.",
                        icon: "warning",
                        button: "OK",
                        dangerMode: true,
                        className: "modal_class",
                    });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw new Error("User Rejected");
            }
        } else {
            throw new Error("No MetaMask Wallet found");
        }
        // console.log("MetaMask provider", provider);
        return provider;
    };

    const setID = async () => {
        try {
            if (ethereum) {
                console.log("No ethereum object");
            }
        } catch (error) {
            console.log(error);
            throw new Error("No ethereum object");
        }
    };
    async function getPairDetails() {
        try {
            const params = {
                url: 'https://api.uniticexchange.com/api/v1/trade/checkPair',
                method: "POST",
                data: { pair: 'UNITIC_INR' },
            };
            const response = await makeRequest(params);
            if (response.status && response.Message) {
                setPairDetails(response.Message);
                setLoaderPrice(() => false)
            }
            setLoaderPrice(() => false)
        } catch (err) {
            console.log("err", err);
            setLoaderPrice(() => false)
        }
    }
    async function getPriceDetails() {
        try {
            const params = {
                url: 'https://api.uniticexchange.com/api/v1/trade/checkPair',
                method: "POST",
                data: { pair: 'BTC_USDT' },
            };
            const params2 = {
                url: 'https://api.uniticexchange.com/api/v1/trade/checkPair',
                method: "POST",
                data: { pair: 'ETH_USDT' },
            };
            const response = await makeRequest(params);
            const response2 = await makeRequest(params2);
            // console.log(response.Message,
            //     response2.Message)
            if (response.status && response.Message && response2.status && response2.Message) {
                setPriceDetails(() => {
                    return {
                        btc: response.Message,
                        eth: response2.Message,
                    }
                });
            }
        } catch (err) {
            console.log("err", err);
        }
    }
    useEffect(() => {
        getPriceDetails()
    }, [])

    useEffect(() => {
        checkIfWalletIsConnect();
    }, []);
    const getReferralData = async () => {
        const stakeData = await getUserReferralData(user?.walletAddress)
        if (stakeData.status && (stakeData.data).length > 0) {
            setGetReferralStake(() => stakeData.data[0]?.referralUser)
        }
        else {
            setGetReferralStake(() => [])
        }
    }
    const getTheCurrent = async () => {
        // console.log("usering.....1");
        setWalletLoading(true);
        setLoading(true);
        await axios
            .get(`${Config.V1_API_URL}wallet-user/`, {
                headers: {
                    authorization: `Bearer ${getCookie("userOfuAndCo")}`,
                },
            })
            .then((res) => {
                setUser(res.data);
                getBalanceTestnet()
                getUserBalance();
                getReferralData();
                // if (res.data && (!res.data?.steps?.step1 || !res.data?.steps?.step2 || !res.data?.steps?.step3 || !res.data?.steps?.step4)) {
                //     navigate('/sign-up')
                // }
                // if (res.data && (!res.data?.kyc?.status != 3)) {
                //     navigate('/kyc')
                // }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                setWalletLoading(false);
                // console.log("usering.....2");
            });
        // setUserRefetch(false);
    }

    useEffect(() => {
        if (currentAccount || getCookie("userOfuAndCo")) {
            getTheCurrent();
            getPairDetails();
            // getBalanceTestnet();
        }
    }, [currentAccount, userRefetch, getCookie("userOfuAndCo")]);

    useEffect(() => {
        if (requestLoading) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>UNITIC Transaction Pending...<b></p> `;
            swal({
                content: wrapper,
                button: false,
                className: "modal_class_success",
            });
        }
    }, [requestLoading]);

    useEffect(() => {
        if (withdrawLoading) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Withdraw Processing...<b></p> `;
            swal({
                content: wrapper,
                button: false,
                className: "modal_class_success",
            });
        }
    }, [withdrawLoading]);
    return (
        <GlobalContext.Provider value={{
            connectWallet,
            currentAccount,
            loginModal,
            setLoginModal,
            requestLoading,
            setRequestLoading,
            walletModal,
            user,
            setUser,
            logOut,
            loading,
            Id,
            setID,
            setUserRefetch,
            userRefetch,
            chain,
            pageLoading,
            payAmount,
            setPayAmount,
            metamaskBalance,
            setWalletModal,
            coinbaseModal,
            metamaskBalanceLoading,
            getBalanceTestnet,
            getUserBalance,
            closeWalletModal,
            closeCoinbaseModal,
            openWalletModal,
            openCoinbaseModal,
            openLoginModal,
            closeLoginModal,
            destination,
            setDistination,
            setMetamaskBalanceLoading,
            connectToCoinbase,
            connectToMetamask,
            connectToTrustWallet,
            signBuyFunction,
            goToProfile,
            setGoToProfile,
            anchorEl,
            setAnchorEl,
            openWalletModalWarning,
            closeWalletModalWarning,
            walletWarning,
            contractData,
            stakeListRefetch,
            setStakeListRefetch,
            walletLoading,
            siteSettings,
            getTheCurrent,
            getPairDetails,
            loaderPrice,
            setLoaderPrice,
            pairDetails,
            planAmount1,
            setPlanAmount1,
            getReferralData,
            getReferralStake,
            userWallet,
            priceDetails,
            getPriceDetails,
            seconds,
            setSeconds,
            transferUniticWithArbirum
        }}>{children}</GlobalContext.Provider>
    )
}