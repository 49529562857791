"use client";
import * as React from 'react';
import { Button } from "flowbite-react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { forwardRef, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './walletlogin.css'
import { useGlobalContextData } from '../../core/context/AdminContext/GlobalContext';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});


export default function WalletLogin({ open, setOpen }) {
    const { connectToMetamask, walletModal, closeWalletModal, goToProfile, setGoToProfile } = useGlobalContextData();

    return (
        <div>
            <Dialog
                open={walletModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={closeWalletModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="dialogWallet pt-4" style={{ backgroundColor: '#060C13' }}>
                    <DialogContent className='alertWalletDiv' >
                        <DialogContentText id="alert-dialog-slide-description">
                            <div className='grid grid-cols-1 gap-2'>
                                <Card className='walletDiv walletModal_icon text-center' onClick={''} style={{ backgroundColor: 'transparent', cursor: 'pointer', color: '#989898' }}>
                                    <img alt='' style={{ width: "50px" }} src="https://i.ibb.co/vVf533V/1.png" className="maskImg mx-auto" />
                                    <CardContent className='border-0'>
                                        <Typography gutterBottom variant="h5" component="div" className='walletName'>
                                            Metamask
                                        </Typography>
                                        <button
                                            onClick={() => connectToMetamask()}
                                            className='btn-login color-5'>
                                            Click here to login
                                        </button>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className="contentDiv text-center mt-2" style={{lineHeight:'1.5rem', color: '#989898' }}>
                                <p className='contents mb-0'>You can use <span className='text-warning'>Polygon</span> Chain to connect.</p>
                                <p className='contents mb-0'>Add <span className='text-warning'>Polygon</span> Chain in your Metamask as follows.</p>
                                <p className='contents px-2 break-all'><a className='contents1' style={{ color: '#00ACED' }} href="https://wiki.polygon.technology/docs/tools/wallets/metamask/config-polygon-on-metamask" target="_any" >https://wiki.polygon.technology/docs/tools/wallets/metamask/config-polygon-on-metamask</a></p>
                            </div>
                            <p className='text-center flex justify-center items-center mt-4'>
                                <Button onClick={closeWalletModal} className="text-white text-base" color="failure">Cancel</Button>
                            </p>
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
}