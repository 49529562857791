import React, { useEffect, useState } from 'react';
import './Register.css';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './step5';
import Step6 from './Step6';
import { useGlobalContextData } from '../../../core/context/AdminContext/GlobalContext';
import { toast } from '../../../core/lib/toastAlert';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';

const Register = () => {
    const [step, setStep] = useState(1);
    const { user, loading,setUser } = useGlobalContextData();
    const navigate = useNavigate();
    useEffect(() => {
        if (!loading && !user?.walletAddress) {
            toast({ type: 'error', message: 'Please Connect Wallet First' })
            return navigate('/')
        }
    }, [user, loading])
    if (loading) {
        return <Loader />
    }
    return (
        <section className='bg-default flex justify-center items-center py-10' style={{ minHeight: '91vh' }}>
            <div className='container mx-auto px-4'>
                <div className='bg-auth-card mx-auto w-full lg:w-2/4 py-5'>
                    <div>
                        <h3 className='text-center text-2xl md:text-4xl page-sub-title'>Sign up</h3>
                        <div className='flex justify-center items-center'>
                            <p className='text-xs md:text-sm text-justify page-sub-desc pt-2'>Let’s get you all st up so you can access your personal account.</p>
                        </div>
                    </div>
                    {
                        step == 1 ? <Step1 step={step} setStep={setStep} user={user} setUser={setUser}/> : step == 2 ? <Step2 step={step} setStep={setStep} user={user} setUser={setUser}/> : step == 3 ? <Step3 step={step} setStep={setStep} user={user} setUser={setUser} /> : step == 4 ? <Step4 step={step} setStep={setStep} user={user} setUser={setUser} /> : step == 5 ? <Step5 step={step} setStep={setStep} user={user} setUser={setUser} /> : step == 6 ? <Step6 step={step} setStep={setStep} user={user} setUser={setUser} /> : null
                    }
                </div>
            </div>
        </section>
    );
};

export default Register;