import USDT from "./USDTTESTNET.json";
import UNITIC from "./arbitrum.json";
import smartcontract from "./Smartcontract.json"
import smartcontractarbitrum from "./Smartcontractarbitrum.json"


export const USDTtokenAddressTestnet = "0xaE6e203ca4Ed0Fa0Eee96A797da670ea1D62CE95"; 
export const USDTtokenABITest = USDT.abi;

export const arbitrumAddress = "0x1D4783444F529614DADA51C063024496bF50f51c";
export const ArbitrumABI = UNITIC.abi;

export const SmartcontractAddressarbitrum = "0x5329c300bCfCb12cA395c97311a4A44308c7A59b";
export const SmartcontractarbitrumABI = smartcontractarbitrum.abi;

export const SmartcontractAddress = "0xBd0D2BC5E6Dfea248060aD1CE590498A061d5cAb";
export const SmartcontractABI = smartcontract.abi;