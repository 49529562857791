import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import './Dashboard.css';
import { useGlobalContextData } from '../../core/context/AdminContext/GlobalContext';
import { toast } from '../../core/lib/toastAlert';
import Config from '../../core/config';

const drawerWidth = 240;

const lists = [
    { name: 'Dashboard', path: "/dashboard/home", id: 1 },
    { name: 'Profile', path: "profile", id: 2 },
    { name: 'U&Co Stake', path: "uandco", id: 7 },
    { name: 'Insta Trade', path: "insta-trade", id: 4 },
    { name: 'Deposit/Withdraw', path: "deposit-withdraw", id: 8 },
    { name: 'Geneology', path: "geneology", id: 6 },
    { name: 'Payout', path: "payout", id: 3 },
]

function Dashboard(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const navigate = useNavigate()
    const pathname = useLocation().pathname;
    const { user } = useGlobalContextData()
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({ type: 'success', message: 'Copied The Link!' })
        }, (err) => {
            toast({ type: 'error', message: 'Failed To Copy!' })
        });
    };
    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };
    const coreLists = <List>
        {lists.map((text) => {
            const { id = 1, path = '', name = '', isLog = false } = text
            const isActive = pathname.includes(path)
            return (
                <ListItem key={id} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        onClick={() => { if (isLog) { } else { navigate(path); localStorage.setItem('page', name) } handleDrawerClose() }}
                        onKeyDown={handleDrawerClose}
                        sx={{
                            minHeight: 48,
                            justifyContent: 'initial',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: 3,
                                justifyContent: 'center',
                                color: isActive ? '#5A45CE' : '',
                                transition: '0.2s all ease-in-out',
                                height: isActive ? '30px' : 'auto',
                            }}
                        >
                            <img src='/assets/dIcon.svg' alt='' />
                        </ListItemIcon>
                        <ListItemText primary={name} sx={{ borderBottom: isActive ? '4px solid #5A45CE' : '4px solid #fff', }} />
                    </ListItemButton>
                </ListItem>
            )
        })}
    </List>
    const drawer = (
        <div>
            <Toolbar className='flex flex-col justify-center items-center pt-5'>
                <div className='flex justify-center items-center flex-col'>
                    <Avatar alt="logo" src={user?.avatar?.url || "/assets/user.svg"} sx={{ width: 80, height: 80, bgcolor: '#F8F8F8' }} />
                    <p className='font-pop pt-2' style={{ color: 'black' }}>{user?.firstName}</p>
                </div>
                <div className='py-3'>
                    <div className=''>
                        <p className='font-pop pt-2 text-sm'>Referral ID</p>
                    </div>
                    <div className='relative w-full'>
                        <input className='text-sm w-48 md:w-44 focus:border-0 focus:outline-none h-7 md:text-base ps-0 pr-7 md:pr-6 border-0 border-b-2' type='text' value={user?.isStake ? user?.walletAddress : 'No Stake'} />
                        <span className='absolute bottom-2 md:bottom-2 right-0 md:right-0 text-sm md:text-base cursor-pointer' onClick={() => {
                            if (user?.isStake) {

                                copyToClipboard(Config?.FRONEND_URL + user?.walletAddress)
                            }
                            else {
                                toast({ type: 'error', message: 'Stake For Referral link' })
                            }
                        }} style={{ zIndex: 500, color: '#1A8300', fontFamily: '"Poppins", sans-serif' }}><img src='/assets/copy.svg' alt='copy' width={20} /></span>
                    </div>
                </div>
            </Toolbar>
            {/* <Divider /> */}
            {coreLists}
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (

        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    top: 72,
                    zIndex: 1,
                    display: { sm: 'none' },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: 73, zIndex: 1 },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{

                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: 73, zIndex: 1 },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, minHeight: '91vh', width: `calc(100% - ${drawerWidth}px)` }}
            >
                <section className='bg-default flex justify-center items-center pt-20 sm:pt-8 pb-5 md:pb-3 md:pt-5'>
                    <div className='container mx-auto px-4'>
                        <Outlet />
                    </div>
                </section>
            </Box>
        </Box>
    );
}


export default Dashboard;
