import React from 'react';

const UnlimitedBenefits = () => {
    return (
        <section className='py-7 bg-sub-home'>
            <div className='container mx-auto px-4'>
                <div>
                    <h1 className='text-center text-2xl md:text-4xl home-title'>The Ultimate Benefits</h1>
                    <div className='flex justify-center items-center'>
                        <p className='text-xs md:text-sm text-justify home-sub-title pt-2'>Build & Scale your Project with our most Compressive set of<br/>web3 Tools .We Provide wallets, markets, NFT tools and more</p>
                    </div>
                </div>
                <div className='pt-10 flex flex-col md:flex-row justify-between items-center' style={{ gap: '15px' }}>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                            <img src='/assets/benefit_1.svg' alt='' className='img-fluid' width={480} />
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                            <img src='/assets/benefit_2.svg' alt='' className='img-fluid' width={480} />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default UnlimitedBenefits;