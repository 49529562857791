import React, { useEffect, useRef, useState } from 'react';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { toast } from '../../../../core/lib/toastAlert';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Spinner } from 'flowbite-react';
import Config from '../../../../core/config';
import { makeRequestWihActualStatus } from '../../../../core/services/v1/request';

const SellTab = ({ pairDetails, getInstaSellTrade, getUserBalance,bankDetails }) => {
    const { price = 0, pair = '' } = pairDetails;
    const fileInputRef1 = useRef(null);
    const [previewImg, setPreviewImg] = useState('');
    const [img, setImg] = useState('');
    const { siteSettings, user } = useGlobalContextData()
    const [cryptoQuant, setCryptoQuant] = useState();
    const [getTotalInfo, setGetTotalInfo] = useState(0);
    const [buycryptofees, setbuycryptofees] = useState('0%');
    const [loading, setLoading] = useState(false);
    const [sellcryptoinrpricePro, setSellcryptoinrpricePro] = useState();

    // useEffect(() => {
    //     // console.log('s ',siteSettings)
    //     if (siteSettings) {
    //         setbuycryptofees(siteSettings['BuyCryptoFees'] + '%');
    //     }
    // }, [siteSettings])
    const handleLabelClick1 = () => {
        fileInputRef1?.current.click(); // Trigger the hidden file input when the label is clicked
    };
    const handleChange2 = (event) => {
        // let fees = 0;
        // if (siteSettings) {
        //     fees = Number(siteSettings['BuyCryptoFees']);
        // }
        setCryptoQuant(() => event.target.value);
        // let inr = sellcryptoinrpricePro * Number(event.target.value);
        // // console.log(typeof sellcryptoinrpricePro, typeof event.target.value, inr, typeof inr);

        // const percentage = (fees + 1) / 100;
        // // let getTotal = inr - inr * percentage;

        // if (inr > 0) {

        //     let getTotal = inr - (inr * percentage);
        //     setGetTotalInfo(() => (getTotal).toFixed(2));
        // }
        // else {
        //     setGetTotalInfo(() => (0).toFixed(2));
        // }

        let result = event.target.value * price;
        // result = result - (result * 0.004);
        // result = result - (result * 0.01);
        setGetTotalInfo(() => (result).toFixed(2));
    }
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({ type: 'success', message: 'Copied The Link!' })
        }, (err) => {
            toast({ type: 'error', message: 'Failed To Copy!' })
        });
    };
    const handleChange = (event) => {

        let fees = ''
        if (siteSettings) {
            fees = Number(siteSettings['BuyCryptoExtraPriceForSell']);
        }
        const twoPercent = parseFloat(price) * fees; // calculate 2% of the number
        const result = parseFloat(price) + twoPercent; // add 2% to the number
        // console.log(element, "element")
        setSellcryptoinrpricePro(() => result?.toFixed(2));
    }
    useEffect(() => {
        handleChange()
    })
    const clearPreview1 = () => {
        setPreviewImg(() => '');
        setImg(() => '');
        if (fileInputRef1.current) {
            fileInputRef1.current.value = '';
        }
    };
    const selectFile = (event) => {
        const file = event.target.files[0];
        setImg(() => file?.name || '')
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImg(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onSubSell = async (e) => {
        e.preventDefault();
        setLoading(() => true)

        const formDataSell = new FormData();
        formDataSell.append('userId', user?._id);
        formDataSell.append('transImage', e.target.upImg.files[0]);
        formDataSell.append('currencyAmount', getTotalInfo?.toString());
        formDataSell.append('transaction', e.target.transaction.value);
        formDataSell.append('type', 'sell');
        formDataSell.append('currencyName', 'INR');
        formDataSell.append('cryptoamount',cryptoQuant?.toString()+' UNITIC');

        // console.log(data, "data");
        const params = {
            url: `${Config.V1_API_URL}insta-trade/sell`,
            method: 'POST',
            body: formDataSell,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const response = (await makeRequestWihActualStatus(params));
        if (response.status) {
            toast({ type: 'success', message: response.message })
            getInstaSellTrade()
            getUserBalance();
            setLoading(() => false)
            e.target.reset()
            clearPreview1()
            setGetTotalInfo(() => 0)
            setCryptoQuant(() => 0)
        }
        else {
            toast({ type: 'error', message: response.message })
            setLoading(() => false)
        }
        setLoading(() => false)
    }

    return (
        <div className='pt-5 pb-2'>
            <form onSubmit={onSubSell} className='dProfile-form-inputs mx-auto px-3 md:px-4'>
                <label className='text-sm md:text-base'>Coin</label><br />
                <select className='special-custom' onChange={handleChange}>
                    <option className='special-custom' value={(pair)?.split('_')[0]} key={(pair)?.split('_')[0]}>{(pair)?.split('_')[0]}</option>
                </select>
                <br />
                <label className='text-sm md:text-base'>Price</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='text' value={price} readOnly />
                    <span className='absolute bottom-8 md:bottom-7 right-5 text-sm cursor-pointer font-pop' onClick={(() => { })}>Max</span>
                </div>
                <div className='flex justify-between items-start mt-2 mb-4'>
                    <label className='text-sm' style={{wordBreak:'break-all', whiteSpace:'normal'}}>Deposit Address : {bankDetails?.depositAddress}</label>
                    <label className='text-sm cursor-pointer' onClick={() => copyToClipboard(bankDetails?.depositAddress)}><img src='/assets/copy.svg' alt='' width={20} /></label>
                </div>
                <label className='text-sm md:text-base'>Quantity Sent</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base' type='text' required value={cryptoQuant} onChange={handleChange2} />
                    <span className='absolute bottom-8 md:bottom-7 right-5 text-sm cursor-pointer font-pop'>UNITIC</span>
                </div>
                <label className='text-sm md:text-base'>Transaction  Hash</label><br />
                <input className='text-sm md:text-base' type='text' required name='transaction' /> <br />
                <label className='text-sm md:text-base'>Upload Image</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='text' value={img} readOnly onClick={(() => handleLabelClick1())} />
                    <input
                        type="file"
                        id="upImg"
                        name="upImg"
                        accept="image/*"
                        ref={fileInputRef1}
                        onChange={selectFile}
                        required
                        style={{ display: "none", marginBottom: '0px' }}
                    />
                    <span className='absolute bottom-8 md:bottom-7 right-4 text-sm md:text-base cursor-pointer' onClick={(() => handleLabelClick1())} style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 25 25" fill="none">
                        <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12 14.9991V3.61914" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg></span>
                </div>
                {previewImg &&
                    <div className='mb-2 mt-1'>
                        <Badge badgeContent={<ClearIcon fontSize='small' />} color="error" sx={{ cursor: 'pointer' }} onClick={() => { clearPreview1() }}>
                            <img src={previewImg} alt='' className='img-fluid' width={100} />
                        </Badge> <br />
                    </div>
                }
                <div className='flex justify-between items-center  mt-2 mb-4'>
                    <label className='text-sm'>Fess: {buycryptofees}</label>
                    <label className='text-sm'>TDS: 0%</label>
                </div>
                <label className='text-sm md:text-base'>You Get</label><br />
                <div className='relative'>
                    <input className='text-sm md:text-base pr-20' type='text' required value={getTotalInfo} readOnly />
                    <span className='absolute bottom-8 md:bottom-7 right-5 text-sm cursor-pointer font-pop' onClick={(() => { })}>INR</span>
                </div>
                <button type='submit' className='btn-theme-color text-base' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                    <span className="pl-3">Loading...</span></> : 'Submit'}</button>
            </form>
            {/* <ResultTable /> */}
        </div>
    );
};

export default SellTab;