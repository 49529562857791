import React, { useEffect, useRef, useState } from 'react';
import '../Authentication/Register/Register.css';
import { RegistrationSuccess } from '../../Components/Modals/RegistrationSuccess';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { useGlobalContextData } from '../../core/context/AdminContext/GlobalContext';
import Config from '../../core/config';
import { makeRequestWihActualStatus } from '../../core/services/v1/request';
import { toast } from '../../core/lib/toastAlert';
import { Spinner } from 'flowbite-react';

const KycBank = () => {
    const [openModal, setOpenModal] = useState(false);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const [pan, setPan] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [previewImgPan, setPreviewImgPan] = useState('');
    const [previewImgAadhar, setPreviewImgAadhar] = useState('');
    const { user, setUser } = useGlobalContextData();
    const navigate = useNavigate();

    const handleLabelClick1 = () => {
        fileInputRef1?.current.click();
    };
    const handleLabelClick2 = () => {
        fileInputRef2?.current.click();
    };
    const selectFile = (event) => {
        const file = event.target.files[0];
        setPan(() => file?.name || '')
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImgPan(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const selectFile2 = (event) => {
        const file = event.target.files[0];
        setAadhar(() => file?.name)
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImgAadhar(reader.result);
            };
            reader.readAsDataURL(file);
        }
        // setPreviewImage(URL.createObjectURL(event.target.files[0]));
    };

    useEffect(() => {
        if (user?.kyc?.status == 3 && user?.bankDetails?.status == 3) {
            return navigate('/dashboard/home')
        }
    }, [user])

    const onSubKyc = async (e) => {
        e.preventDefault();
        setLoading(() => true)
        const formData = new FormData();
        formData.append('panCard', e.target.panCard.files[0]);
        formData.append('panNumber', e.target.panNumber.value);
        formData.append('aadharCard', e.target.aadharCard.files[0]);
        formData.append('aadharNumber', e.target.aadharNumber.value);

        const params = {
            url: `${Config.V1_API_URL}wallet-user/kyc-upload`,
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            setUser((data) => { return { ...data, kyc: { ...data?.kyc, status: 1, panStatus: 1, aadharStatus: 1 } } })
            toast({ type: 'success', message: res.message })
            clearPreview1();
            clearPreview2();
            e.target.reset();
            setLoading(() => false)
            // setOpenModal(() => true)
        }
        else {
            setLoading(() => false)
            toast({ type: 'error', message: res.message })
        }

        setLoading(() => false)
    }

    const clearPreview1 = () => {
        setPreviewImgPan(() => '');
        setPan(() => '');
        if (fileInputRef1.current) {
            fileInputRef1.current.value = '';
        }
    };
    const clearPreview2 = () => {
        setPreviewImgAadhar(() => '');
        setAadhar(() => '');
        if (fileInputRef2.current) {
            fileInputRef2.current.value = '';
        }
    };

    const onSubBank = async (e) => {
        e.preventDefault();
        setLoading2(() => true)
        const accountName = e.target.accountName.value;
        const accountNumber = e.target.accountNumber.value;
        const ifscCode = e.target.ifscCode.value;
        const bankName = e.target.bankName.value;

        const params = {
            url: `${Config.V1_API_URL}wallet-user/bank-upload`,
            method: 'PUT',
            body: { accountName, accountNumber, ifscCode, bankName },
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            setUser((data) => { return { ...data, bankDetails: { ...data?.bankDetails, status: 1 } } })
            toast({ type: 'success', message: res.message })
            e.target.reset();
            setLoading(() => false)
            // setOpenModal(() => true)
        }
        else {
            setLoading(() => false)
            toast({ type: 'error', message: res.message })
        }

        setLoading2(() => false)
    }

    return (
        <section className='bg-default flex justify-center items-center py-10' style={{ minHeight: '91vh' }}>
            <div className='container mx-auto px-4 flex justify-between items-start gap-8 flex-col lg:flex-row'>
                <div className='bg-auth-card mx-auto w-full lg:w-2/4 py-5'>
                    <div>
                        <h3 className='text-center text-2xl md:text-2xl page-sub-title'>KYC Verification</h3>
                    </div>
                    <div className='pt-1'>
                        <form onSubmit={onSubKyc} className='form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Pan Card [Less Then 5MB] *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base pr-20' type='text' disabled={user?.kyc?.panStatus != 0} value={pan} readOnly onClick={(() => handleLabelClick1())} />
                                <input
                                    type="file"
                                    id="pan"
                                    name='panCard'
                                    ref={fileInputRef1}
                                    accept="image/*"
                                    onChange={selectFile}
                                    required disabled={user?.kyc?.panStatus != 0}
                                    style={{ display: "none", marginBottom: '0px' }}
                                />
                                <span className='absolute bottom-8 md:bottom-7 right-2 text-sm md:text-base cursor-pointer' onClick={(() => handleLabelClick1())} style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 14.9991V3.61914" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span>
                                <span className='absolute bottom-8 md:bottom-8 right-10 text-sm cursor-pointer capitalize'>{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}</span>
                            </div>
                            {previewImgPan &&
                                <div className='mb-2 mt-1'>
                                    <Badge badgeContent={<ClearIcon fontSize='small' />} color="error" sx={{ cursor: 'pointer' }} onClick={() => { clearPreview1() }}>
                                        <img src={previewImgPan} alt='' className='img-fluid' width={100} />
                                    </Badge> <br />
                                </div>
                            }
                            <label className='text-sm md:text-base'>Enter Your Pan Number *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.kyc?.panStatus != 0} name='panNumber' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}</span>
                            </div>
                            <label className='text-sm md:text-base'>Aadhar Card [Less Then 5MB] *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base pr-20' type='text' disabled={user?.kyc?.aadharStatus != 0} value={aadhar} readOnly onClick={(() => handleLabelClick2())} />

                                <input
                                    type="file"
                                    id="aadhar"
                                    accept="image/*"
                                    name='aadharCard'
                                    ref={fileInputRef2}
                                    onChange={selectFile2}
                                    required disabled={user?.kyc?.aadharStatus != 0}
                                    style={{ display: "none", marginBottom: '0px' }}
                                />
                                <span className='absolute bottom-8 md:bottom-7 right-2 text-sm md:text-base cursor-pointer' onClick={(() => handleLabelClick2())} style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.4405 8.90039C20.0405 9.21039 21.5105 11.0604 21.5105 15.1104V15.2404C21.5105 19.7104 19.7205 21.5004 15.2505 21.5004H8.74047C4.27047 21.5004 2.48047 19.7104 2.48047 15.2404V15.1104C2.48047 11.0904 3.93047 9.24039 7.47047 8.91039" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M12 14.9991V3.61914" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M15.3504 5.85L12.0004 2.5L8.65039 5.85" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></span>
                                <span className='absolute bottom-8 md:bottom-8 right-10 text-sm cursor-pointer    capitalize'>{user?.kyc?.aadharStatus == 0 ? 'incomplete' : user?.kyc?.aadharStatus == 1 ? 'pending' : user?.kyc?.aadharStatus == 2 ? 'rejected' : user?.kyc?.aadharStatus == 3 ? 'approved' : ''}</span>
                            </div>
                            {previewImgAadhar &&
                                <div className='mb-2 mt-1'>
                                    <Badge badgeContent={<ClearIcon fontSize='small' />} color="error" sx={{
                                        cursor: 'pointer'
                                    }} onClick={() => { clearPreview2() }}>
                                        <img src={previewImgAadhar} alt='' className='img-fluid' width={100} />
                                    </Badge> <br />
                                </div>
                            }
                            <label className='text-sm md:text-base'>Enter Your Aadhar Number *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.kyc?.aadharStatus != 0} name='aadharNumber' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.kyc?.aadharStatus == 0 ? 'incomplete' : user?.kyc?.aadharStatus == 1 ? 'pending' : user?.kyc?.aadharStatus == 2 ? 'rejected' : user?.kyc?.aadharStatus == 3 ? 'approved' : ''}</span>
                            </div>
                            <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading || user?.kyc?.aadharStatus != 0 || user?.kyc?.panStatus != 0}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                <span className="pl-3">Loading...</span></> : 'Submit'}</button>
                        </form>
                    </div>
                </div>

                <div className='bg-auth-card mx-auto w-full lg:w-2/4 py-5'>
                    <div>
                        <h3 className='text-center text-2xl md:text-2xl page-sub-title'>Bank Verification</h3>
                    </div>
                    <div className='pt-1'>
                        <form onSubmit={onSubBank} className='form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Account Name *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.bankDetails?.status != 0} name='accountName' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.bankDetails?.status == 0 ? 'incomplete' : user?.bankDetails?.status == 1 ? 'pending' : user?.bankDetails?.status == 2 ? 'rejected' : user?.bankDetails?.status == 3 ? 'approved' : ''}</span>
                            </div>
                            <label className='text-sm md:text-base'>Account Number *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.bankDetails?.status != 0} name='accountNumber' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.bankDetails?.status == 0 ? 'incomplete' : user?.bankDetails?.status == 1 ? 'pending' : user?.bankDetails?.status == 2 ? 'rejected' : user?.bankDetails?.status == 3 ? 'approved' : ''}</span>
                            </div>
                            <label className='text-sm md:text-base'>IFSC Code *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.bankDetails?.status != 0} name='ifscCode' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.bankDetails?.status == 0 ? 'incomplete' : user?.bankDetails?.status == 1 ? 'pending' : user?.bankDetails?.status == 2 ? 'rejected' : user?.bankDetails?.status == 3 ? 'approved' : ''}</span>
                            </div>
                            <label className='text-sm md:text-base'>Bank Name *</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base' type='text' disabled={user?.bankDetails?.status != 0} name='bankName' required />
                                <span className='absolute bottom-8 md:bottom-8 right-4 text-sm cursor-pointer capitalize'>{user?.bankDetails?.status == 0 ? 'incomplete' : user?.bankDetails?.status == 1 ? 'pending' : user?.bankDetails?.status == 2 ? 'rejected' : user?.bankDetails?.status == 3 ? 'approved' : ''}</span>
                            </div>
                            <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading2 || user?.bankDetails?.status != 0}>{loading2 ? <><Spinner aria-label="Spinner button example" size="sm" />
                                <span className="pl-3">Loading...</span></> : 'Submit'}</button>
                        </form>
                    </div>
                </div>
            </div>
            {openModal ? <RegistrationSuccess openModal={openModal} setOpenModal={setOpenModal} /> : null}
        </section>
    );
};

export default KycBank;