import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { toast } from '../../../../core/lib/toastAlert';
import { dateFormat } from '../../../../core/helper/date-format';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Spinner } from 'flowbite-react';
import Config from '../../../../core/config';
import { makeRequestWihActualStatus } from '../../../../core/services/v1/request';

const DProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadTime, setUploadTime] = useState(false);
    const [files, setFiles] = useState(null);
    const [previewImg, setPreviewImg] = useState('');
    const { user, setUser } = useGlobalContextData();

    const onDrop = useCallback(async (acceptedFiles) => {
        if (user?.avatar?.url) {
            return toast({ type: 'error', message: 'You have already!' })
        }
        setIsLoading(true);

        try {
            setUploadTime(true);
            const file = acceptedFiles[0];
            setFiles(() => file);
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewImg(reader.result);
                };
                reader.readAsDataURL(file);
            }
            setTimeout(() => {
                setUploadTime(false);
            }, 1500);



        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png', '.jpg', '.svg']
        },
        disabled: isLoading || user?.avatar?.url,
    });
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast({ type: 'success', message: 'Copied The Link!' })
        }, (err) => {
            toast({ type: 'error', message: 'Failed To Copy!' })
        });
    };
    const clearPreview1 = () => {
        setPreviewImg(() => '');
        setFiles(() => null);
    };

    const onSubDP = async () => {
        if (user?.avatar?.url) {
            return toast({ type: 'error', message: 'You have already!' })
        }
        setLoading(() => true)

        const formData = new FormData();
        formData.append('avatar', files);

        // console.log(data, "data");
        const params = {
            url: `${Config.V1_API_URL}wallet-user/profile-avatar`,
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        const response = (await makeRequestWihActualStatus(params));
        if (response.status) {
            toast({ type: 'success', message: response.message })
            setUser((data) => { return { ...data, avatar: { id: response.data?.avatar?.id, url: response.data?.avatar?.url } } })
            setLoading(() => false)
            clearPreview1()
        }
        else {
            toast({ type: 'error', message: response.message })
            setLoading(() => false)
        }
        setLoading(() => false)
    }
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>My Profile</h4>
            </div>

            <div className='flex justify-center items-center my-5'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>Personal Information</p>
                        </div>
                        <div>
                            {/* <button className='btn-theme-color px-7 py-1 h-8 md:h-auto text-sm md:text-base text-white'> Edit</button> */}
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={e => { e.preventDefault(); }} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Referral-id</label><br />
                            <div className='relative'>
                                <input className='text-sm md:text-base pr-20' type='text' value={user?.isStake ? user?.walletAddress : 'No Stake'} readOnly />
                                <span className='absolute bottom-8 md:bottom-7 right-5 text-sm md:text-base cursor-pointer' onClick={() => {
                                    if (user?.isStake) {

                                        copyToClipboard(Config?.FRONEND_URL + user?.walletAddress)
                                    }
                                    else {
                                        toast({ type: 'error', message: 'Stake For Referral link' })
                                    }
                                }} style={{ zIndex: 500, color: '#4528E1', fontFamily: '"Poppins", sans-serif' }}><img src='/assets/copy.svg' alt='' /></span>
                            </div>
                            <label className='text-sm md:text-base'>Email </label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.email} readOnly /> <br />
                            <label className='text-sm md:text-base'>First Name </label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.firstName} readOnly /> <br />
                            <label className='text-sm md:text-base'>Last Name</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.lastName} readOnly /> <br />
                            <label className='text-sm md:text-base'>Date of Birthday</label><br />
                            <input className='text-sm md:text-base' type='text' value={dateFormat(user?.dob)} readOnly /> <br />
                            <label className='text-sm md:text-base'>Phone Number</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.phoneNo} readOnly /> <br />
                            <label className='text-sm md:text-base'>Address</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.address?.addressNo} readOnly /> <br />
                            <label className='text-sm md:text-base'>State</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.address?.state} readOnly /> <br />
                            <label className='text-sm md:text-base'>City</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.address?.city} readOnly /> <br />
                            <label className='text-sm md:text-base'>Pincode</label><br />
                            <input className='text-sm md:text-base' type='number' value={user?.address?.zip} readOnly /> <br />
                        </form>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center my-5'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>KYC Information</p>
                        </div>
                        <div>
                            {/* <button className='btn-theme-color px-7 py-1 h-8 md:h-auto text-sm md:text-base text-white'> Edit</button> */}
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={e => { e.preventDefault(); }} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Pan Number [<span className='capitalize'>{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}</span>]</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.kyc.panNumber} readOnly />
                            <br />
                            <label className='text-sm md:text-base'>Pan Card [<span className='capitalize'>{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}</span>]</label><br />
                            <img src={user?.kyc?.panCard?.url} className='mt-2 mb-3' alt='pan' width={300} style={{ border: '1px solid rgb(90, 69, 206)', borderRadius: '5px' }} />
                            <label className='text-sm md:text-base'>Aadhar Number [<span className='capitalize'>{user?.kyc?.aadharStatus == 0 ? 'incomplete' : user?.kyc?.aadharStatus == 1 ? 'pending' : user?.kyc?.aadharStatus == 2 ? 'rejected' : user?.kyc?.aadharStatus == 3 ? 'approved' : ''}</span>]</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.kyc.aadharNumber} readOnly /> <br />
                            <label className='text-sm md:text-base'>Aadhar Card [<span className='capitalize'>{user?.kyc?.aadharStatus == 0 ? 'incomplete' : user?.kyc?.aadharStatus == 1 ? 'pending' : user?.kyc?.aadharStatus == 2 ? 'rejected' : user?.kyc?.aadharStatus == 3 ? 'approved' : ''}</span>]</label><br />
                            <img src={user?.kyc?.aadharCard?.url} className='mt-2 mb-0' alt='aadhar' width={300} style={{ border: '1px solid rgb(90, 69, 206)', borderRadius: '5px' }} />
                        </form>
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center my-5'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>Bank Information</p>
                        </div>
                        <div>
                            <p className='font-pop text-sm md:text-base capitalize' style={{ fontWeight: 400, color: 'var(--T, #313131)' }}>[{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}]</p>
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={e => { e.preventDefault(); }} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Account Name</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.bankDetails?.accountName} readOnly />
                            <br />
                            <label className='text-sm md:text-base'>Account Number</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.bankDetails?.accountNumber} readOnly /><br />
                            <label className='text-sm md:text-base'>IFSC Code</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.bankDetails?.ifscCode} readOnly /> <br />
                            <label className='text-sm md:text-base'>Bank Name</label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.bankDetails?.bankName} readOnly />
                        </form>
                    </div>
                </div>
            </div>

            <div className='flex justify-center items-center mb-5 mt-10'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>Personal Information</p>
                        </div>
                        <div>
                            {/* <button className='btn-theme-color px-7 py-1 text-white h-8 md:h-auto text-sm md:text-base'> Edit</button> */}
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={e => { e.preventDefault(); }} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>First Name </label><br />
                            <input className='text-sm md:text-base' type='text' value={user?.firstName} readOnly /> <br />
                            <div className='upload-div mx-auto text-center w-full mb-5' {...getRootProps()} style={{ cursor: "pointer", background: isDragActive ? '#f2f2f2' : 'white' }}>
                                <div className='upload-space' >

                                    <input {...getInputProps()} />
                                    {isLoading ? (
                                        <p className='text-sm md:text-base font-pop font-semibold'>Uploading... {Math.round(0)}%</p>
                                    ) : isDragActive ? (
                                        <p className='text-sm md:text-base font-pop font-semibold'>Drop the files here ...</p>
                                    ) : (
                                        <>
                                            <h4 className='pt-2 text-sm md:text-base font-pop font-semibold'>Drop Your image Here or Browse</h4></>
                                    )}

                                </div>
                            </div>
                            {previewImg &&
                                <div className='my-2 flex justify-center items-center'>
                                    <div>
                                        <Badge badgeContent={<ClearIcon fontSize='small' />} color="error" sx={{ cursor: 'pointer' }} onClick={() => { clearPreview1() }}>
                                            <img src={previewImg} alt='' className='img-fluid' width={300} />
                                        </Badge>
                                    </div>
                                </div>
                            }
                            <button type='submit' onClick={() => onSubDP()} className='btn-theme-color text-base md:text-lg' disabled={!files || loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                <span className="pl-3">Loading...</span></> : 'Submit'}</button>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default DProfile;