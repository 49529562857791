import React from 'react';
import { Button } from "flowbite-react";
import './Banner.css'
import SearchCrypto from './SearchCrypto';
import { Dropdown } from "flowbite-react";
import CryptoPrices from './CryptoPrices';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    const { openWalletModal, user, connectToTrustWallet, logOut } = useGlobalContextData();
    const navigate = useNavigate();
    return (
        <section>
            <div className='custom-bg-banner flex justify-between items-center'>
                <div className='container mx-auto px-4 h-full'>
                    <div className='flex flex-col md:flex-row justify-between items-center h-full'>
                        <div className='custom-banner-texts w-full'>
                            <div className='w-auto lg:w-9/12 xl:w-8/12'>
                                <div>
                                    <h1 className='text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl'>EARN</h1>
                                </div>
                                <div>
                                    <h3 className='text-lg md:text-2xl lg:text-3xl 2xl:text-4xl'>Easy Money Tree Plan &
                                        Golden Money Tree Plan</h3>
                                    <p className='text-sm lg:text-base'>
                                        Build & Scale your Project with our most Compressive set of
                                        web3 Tools .We Provide wallets, markets, NFT tools and more
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    {
                                        !user?.walletAddress ?
                                            <Dropdown size={'sm'} label="" dismissOnClick={true} renderTrigger={() => <Button color="light" pill className="custom-btn-banner w-40 h-9 md:w-48 md:h-10 lg:w-52 lg:h-12 2xl:w-56 2xl:h-14  items-center">
                                                <span className='text-sm lg:text-base'>GET STARTED</span>
                                            </Button>}>
                                                <Dropdown.Item onClick={() => {
                                                    openWalletModal();
                                                }} ><img src="/assets/metamask.png" alt="metamask" className=' pe-2' width={40} />Meta Mask</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    connectToTrustWallet();
                                                }} ><img src="/assets/trust.png" alt="trust wallet" className='pe-2' width={40} />Trust Wallet</Dropdown.Item>
                                            </Dropdown> :
                                            <Button color="light" pill className="custom-btn-banner w-40 h-9 md:w-48 md:h-10 lg:w-52 lg:h-12 2xl:w-56 2xl:h-14  items-center">
                                                <span className='text-sm lg:text-base' onClick={() => navigate('/dashboard')}>Dashboard</span>
                                            </Button>
                                    }
                                </div>
                                {/* <div className='mt-5'>
                                    <SearchCrypto />
                                </div> */}
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-center md:justify-end'>
                                <img src='/assets/bannerRight.svg' alt='' className='img-fluid w-10/12 md:w-11/12' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CryptoPrices />

        </section>
    );
};

export default Banner;