"use client";
import React, { useEffect, useState } from 'react';
import DHomeUpperCards from './DHomeUpperCards';
import { Progress, Spinner } from 'flowbite-react';
import DHomeModal from './DHomeModal';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { dateFormatOnly } from '../../../../core/helper/date-format';
import Config from '../../../../core/config';
import { makeRequestWithErrorMessage } from '../../../../core/services/v1/request';
import { toast } from '../../../../core/lib/toastAlert';
import swal from "sweetalert";
import WithdrawBankModal from './WithdrawBankModal';
import { useNavigate } from 'react-router-dom';

const DashboardHome = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openModalWithdraw, setOpenModalWithdraw] = useState(false);
    const [easy, setEasy] = useState({})
    const [golden, setGolden] = useState({})
    const [progressEasy, setProgressEasy] = useState(0)
    const { user, getReferralData, getReferralStake, metamaskBalance, getBalanceTestnet, getTheCurrent, pairDetails } = useGlobalContextData();
    // console.log(user)
    useEffect(() => {
        getBalanceTestnet();
        getReferralData()
    }, [])
    const allData = [
        {
            title: <span className='flex justify-between items-center w-full'><span>Total Investment</span>    <div className='form-inputs'>
                <button type='button' onClick={() => navigate('/dashboard/deposit-withdraw')} className='btn-theme-color text-xs' style={{ width: 'fit-content', padding: '0px 12px', height: '30px', background: '#2c11c5' }}>Deposit</button>
            </div></span>, id: 1, desc: user?.totalInvestment || 0
        },
        {
            title: 'Referral Bonus', id: 2, desc: (user?.receivedReferralBonus && (user?.stakes.find(el => el?.name === 'Easy')) ? user?.stakes.find(el => el?.name === 'Easy')?.isEligible : false) ? 150000 : 0
        },
        { title: 'Wallet Balance', id: 3, desc: metamaskBalance?.unitic || 0.0 },
        { title: 'Eligible Withdraw', id: 4, desc: user?.totalEligible || 0.0 },
    ]
    useEffect(() => {
        if (user) {
            setEasy(() => {
                const easyPreliminary = user?.stakes.find(el => el?.name === 'Easy')
                if (easyPreliminary && easyPreliminary.lastInterestDate) {
                    const lastInterestDate = new Date(easyPreliminary.lastInterestDate);

                    if (!isNaN(lastInterestDate.getTime())) {
                        const currentDate = new Date();
                        const easyPreliminaryDate = new Date(easyPreliminary.createdAt);

                        const minPassed = Math.floor((currentDate - easyPreliminaryDate) / 60000);
                        const monthsPassed = Math.floor(minPassed / 43200);
                        // console.log(minPassed,monthsPassed)
                        if (easyPreliminary?.isEligible) {
                            setProgressEasy(12);
                        }
                        else {
                            setProgressEasy(() => (+monthsPassed));
                        }
                    }
                }
                return user?.stakes.find(el => el?.name === 'Easy')
            })
        }
    }, [user])

    const withdrawEasy = async (id = '0') => {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<p class='break-all font-pop text-lg mb-3 font-bold'>Withdraw Request</p>
        <div class='w-full flex justify-start items-center'>
               <div style='width:100%;' class='ms-10'>
                <div class='flex justify-start items-center gap-1 mb-3 w-full'>
                    <p>Unitic: </p>
                    <p>${((+user?.totalEligible) / (+pairDetails?.price))?.toFixed(2) || 0}</p>
                </div>
                <div class='flex justify-start items-center gap-1 mb-3 w-full'>
                    <p>Price: </p>
                    <p>${pairDetails?.price}</p>
                </div>
                <div class='flex justify-start items-center gap-1 w-full'>
                    <p>Plan: </p>
                    <p>${user?.totalInvestment}</p>
                </div></div>
            </div>
        `;
        return swal({
            content: wrapper,
            icon: "warning",
            button: "Confirm",
            // dangerMode: true,
            className: "modal_class_success",
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    setLoading(() => true)
                    const params = {
                        url: `${Config.V1_API_URL}stake-trade/withdraw-stake/easy/${id}`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'Application/json',
                        },
                    }
                    const res = await makeRequestWithErrorMessage(params)
                    if (res.status) {
                        getTheCurrent();
                        toast({ type: 'success', message: res.message })
                        setLoading(() => false);
                    }
                    else {
                        toast({ type: 'error', message: res.message })
                        setLoading(() => false);
                    }
                    setLoading(() => false);
                }
            })


    }
    return (
        <div>
            <div className='flex justify-between items-center flex-wrap gap-x-3 gap-y-5'>
                {
                    allData.map((data, index) => <DHomeUpperCards id={index} data={data} />)
                }
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-14'>
                <div className='w-full p-4 bg-white' style={{ borderRadius: '16px', minHeight: '270px' }}>
                    <div className='flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-x-2'>
                            <div className='status-card-green'><p className='text-xs sm:text-sm'>active</p></div>
                            <div>
                                <p className='font-pop text-xs sm:text-sm font-bold'>12%</p>
                            </div>
                        </div>
                        <div className='date-card'><p className='text-sm sm:text-base'>{dateFormatOnly(easy?.createdAt) || 0}</p></div>
                    </div>
                    <div className='flex justify-between items-center py-8'>
                        <div>
                            <p className='font-pop font-semibold text-sm sm:text-base md:text-lg xl:text-xl' style={{ color: 'black' }}>Easy money Tree Plan</p>
                        </div>
                        <div>
                            <p className='font-pop font-semibold text-sm sm:text-base md:text-lg xl:text-xl' style={{ color: '#5A45CE' }}>₹ {easy?.amount || 0}</p>
                        </div>
                    </div>
                    <div>
                        <Progress
                            progress={+progressEasy * 8.33}
                            size="lg"
                            color='indigo'
                        />
                    </div>
                    <div className='form-inputs pt-2'>
                        <button type='button' onClick={() => withdrawEasy(easy?._id)} className='btn-theme-color text-sm' style={{ width: 'fit-content', padding: '0px 20px', height: '35px' }} disabled={loading || (!easy?.isEligible)}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                            <span className="pl-3">Loading...</span></> : 'Withdraw'}</button>
                    </div>
                </div>
                {/* <div className='w-full p-4 bg-neutral-200 ' style={{ borderRadius: '16px', minHeight: '270px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <div className='status-card-green'><p className='text-xs sm:text-sm'>active</p></div>
                            <div className='status-card-red ml-3'><p className='text-xs sm:text-sm'>Level 1</p></div>
                        </div>
                        <div className='date-card'><p className='text-sm sm:text-base'>02-03-2021</p></div>
                    </div>
                    <div className='flex justify-between items-center py-8'>
                        <div>
                            <p className='font-pop font-semibold text-sm sm:text-base md:text-lg xl:text-xl' style={{ color: 'black' }}>Golden money Tree Plan</p>
                        </div>
                        <div>
                            <p className='font-pop font-semibold text-sm sm:text-base md:text-lg xl:text-xl' style={{ color: '#5A45CE' }}>₹ 3,50,000</p>
                        </div>
                    </div>
                    <div>
                        <Progress
                            progress={100}
                            size="lg"
                            color='indigo'
                        />
                    </div>
                    <div>
                        <p className='font-pop text-sm sm:text-base text-center py-3'>Congratulation you have Complete Level 1 </p>
                        <div className='flex justify-center items-center gap-5'>
                            <p className='text-sm sm:text-base xl:text-lg font-bold'>Coming Soon</p>
                            <p className='font-pop text-sm sm:text-base underline' style={{ color: '#5946CE' }}>withdraw</p>
                            <p className='font-pop text-sm sm:text-base underline' style={{ color: '#26BC00' }}>Next Level</p>
                        </div>
                    </div>
                </div> */}
                <div className='w-full p-4 bg-white' style={{ borderRadius: '16px', minHeight: '270px' }}>
                    <div>
                        <div className='flex justify-between items-center'>
                            <div className='date-card-2'><p className='text-base sm:text-lg font-semibold'>User</p></div>
                            <div className='date-card-2'><p className='text-base sm:text-lg font-semibold'>Referral Date</p></div>
                        </div>
                        <hr className='my-2 text-black' />
                    </div>
                    {
                        (!getReferralStake || getReferralStake.length <= 0) ? <div className='mt-2'>
                            <p>No Referral</p>
                        </div> :
                            <>
                                {
                                    getReferralStake && getReferralStake.map(el => <div key={el?._id}>
                                        <div className={`${el?.isStake ? 'status-card-green' : 'status-card-orange'}  my-2`}><p className='text-xs sm:text-sm'>Level 1</p></div>
                                        <div>
                                            <div className='flex justify-between items-center mt-3'>
                                                <div className='date-card-2'><p className='text-base xl:text-lg font-normal'>{el?.firstName}</p></div>
                                                <div className='date-card-2'><p className='text-base xl:text-lg font-normal'>{dateFormatOnly(el?.createdAt)}</p></div>
                                            </div>
                                            <hr className='my-2 text-black' />
                                        </div>
                                    </div>)
                                }

                            </>
                    }

                </div>
                {/* <div className='w-full p-4 bg-neutral-200' style={{ borderRadius: '16px', minHeight: '270px' }}>
                    <div>
                        <div className='flex justify-between items-center'>
                            <div className='date-card-2'><p className='text-base sm:text-lg font-semibold'>User</p></div>
                            <div className='date-card-2'><p className='text-base sm:text-lg font-semibold'>Referral Date</p></div>
                        </div>
                        <hr className='my-2 text-black' />
                    </div>
                    <div className='mt-2'>
                        <p>No Referral</p>
                    </div>
                    <div className='status-card-red my-2'><p className='text-xs sm:text-sm'>Level 1</p></div>
                    <div>
                        <div className='flex justify-between items-center mt-3'>
                            <div className='date-card-2'><p className='text-base xl:text-lg font-normal'>Vijaya Rajan</p></div>
                            <div className='date-card-2'><p className='text-base xl:text-lg font-normal'>28-04-2024</p></div>
                        </div>
                        <hr className='my-2 text-black' />
                    </div>
                </div> */}
            </div>

            {openModal ? <DHomeModal openModal={openModal} setOpenModal={setOpenModal} /> : null}
            {openModalWithdraw ? <WithdrawBankModal openModal={openModalWithdraw} setOpenModal={setOpenModalWithdraw} loading={loading} withdrawEasy={withdrawEasy} /> : null}
        </div>
    );
};

export default DashboardHome;