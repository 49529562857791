"use client";
import { Modal } from "flowbite-react";
import './Models.css';

export function RegistrationSuccess({ openModal, setOpenModal }) {

    return (
        <>
            <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup className="relative" style={{ zIndex: 600 }} position='center'>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <img src='/assets/rSuccess.png' alt='' className="mx-auto mb-0 h-32 w-32 img-fluid" style={{ marginTop: '-25px' }} />
                        <h3 className="mb-0 text-lg md:text-xl font-pop relative bottom-3 font-semibold" >
                            U & Co
                        </h3>
                        <p className="font-pop text-lg md:text-xl font-semibold">Register Successfully</p>
                        <div className="flex justify-center items-center mt-4 ">
                            <button onClick={() => setOpenModal(false)} className="btn-theme-color reg-popUp-btn px-5 text-sm md:text-base">
                                Done
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
