import React, { useEffect, useState } from 'react';
import './CryptoPrice.css';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';


const CryptoPrices = () => {
    const { priceDetails, getPriceDetails } = useGlobalContextData();
    const [slice, setSlice] = useState(2);
    // console.log(priceDetails)
    const data = [
        { img: '/assets/btc.svg', name: 'Bitcoin', lastPrice: (priceDetails?.btc?.lastPrice || 0).toFixed(2), price: (priceDetails?.btc?.price || 0).toFixed(2), coin: 0 },
        { img: '/assets/eth.svg', name: 'Etherum', lastPrice: (priceDetails?.eth?.lastPrice || 0).toFixed(2), price: (priceDetails?.eth?.price || 0).toFixed(2), coin: 1 },
        { img: '/assets/btc.svg', name: 'Bitcoin', lastPrice: (priceDetails?.btc?.lastPrice || 0).toFixed(2), price: (priceDetails?.btc?.price || 0).toFixed(2), coin: 0 },
        { img: '/assets/eth.svg', name: 'Etherum', lastPrice: (priceDetails?.eth?.lastPrice || 0).toFixed(2), price: (priceDetails?.eth?.price || 0).toFixed(2), coin: 1 },
        { img: '/assets/btc.svg', name: 'Bitcoin', lastPrice: (priceDetails?.btc?.lastPrice || 0).toFixed(2), price: (priceDetails?.btc?.price || 0).toFixed(2), coin: 0 },
        { img: '/assets/eth.svg', name: 'Etherum', lastPrice: (priceDetails?.eth?.lastPrice || 0).toFixed(2), price: (priceDetails?.eth?.price || 0).toFixed(2), coin: 1 },
    ]
    useEffect(() => {
        getPriceDetails()
        if (window.innerWidth <= 375) {
            setSlice(() => 2)
        }
        else if (window.innerWidth > 375 && window.innerWidth <= 600) {
            setSlice(() => 3)
        }
        else if (window.innerWidth > 600 && window.innerWidth <= 840) {
            setSlice(() => 4)
        }
        else if (window.innerWidth > 840 && window.innerWidth <= 1200) {
            setSlice(() => 5)
        }
        else {
            setSlice(() => 6)
        }
    }, [])
    useEffect(() => {
        const intervalId = setInterval(() => {
            getPriceDetails()
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);
    return (
        <div style={{ background: '#181238', minHeight: '10vh' }} className='flex justify-center items-center overflow-hidden'>
            <div className='container mx-auto px-4 flex justify-between items-center'>
                {
                    data.slice(0, slice || 6).map((el, index) => <div className='flex justify-between items-center' key={index} style={{ gap: '8px' }}>
                        <div>
                            <img src={el?.img} alt='' className='img-fluid' style={{
                                width: '60px',
                                height: '60px'
                            }} />
                        </div>
                        <div>
                            <p className='cryptoPrice text-white'>{el?.name}</p>
                            <p className='cryptoPrice' style={{ color: el?.price >= el?.lastPrice ? '#26BC00' : '#FF1010' }}>{el?.price}</p>
                        </div>
                    </div>)
                }
            </div>

        </div>
    );
};

export default CryptoPrices;