import React, { useState } from 'react';
import './SelectedPlan.css'

const SelectedPlan = () => {
    const [plan, setPlan] = useState(1);
    return (
        <section className='bg-default py-5' style={{ minHeight: '91vh' }}>
            <div className='container mx-auto px-4'>
                <div className='flex flex-col sm:flex-row justify-between items-start pt-3 gap-y-5'>
                    <div>
                        <h3 className=' font-pop font-semibold text-xl md:text-2xl'>Congratulation You are select</h3>
                        <h3 className='pt-3 font-pop font-semibold text-xl md:text-2xl' style={{ color: '#5A45CE' }}>Easy Money Tree Plan</h3>
                    </div>
                    <div className='flex justify-between items-start gap-x-3 btn-views'>
                        <button>View Plan</button>
                        <button onClick={() => setPlan((data) => data == 1 ? 2 : 1)}>Change Plan</button>
                    </div>
                </div>

                <div className='w-full flex justify-center items-center mt-10'>
                    <div className='bg-white w-full lg:w-2/4 pt-5 pb-8' style={{ borderRadius: '16px' }}>
                        <div className='px-4'>
                            <h4 className='pb-4 font-pop text-xl font-semibold'>You have pay the Amount for</h4>
                            <p className='pb-4 font-pop text-xl font-semibold'>₹ 350,000.00</p>
                            <p className='pb-4 font-pop text-sm' style={{ color: '#313131' }}>select your payment Methods</p>
                        </div>
                        <hr className='mb-8 mt-4' style={{ background: 'rgba(49, 49, 49, 0.20)', color: 'rgba(49, 49, 49, 0.20)' }} />

                        {
                            plan == 1 ?
                                <div className='flex justify-center items-center flex-col gap-y-7'>
                                    <div className='box-plan'>
                                        <div>
                                            <p className='text-base md:text-lg'>Cash Payments</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className='box-plan'>
                                        <div>
                                            <p className='text-base md:text-lg'>Coin Payments</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                </div> :
                                <div>
                                    <div>
                                        <h4 className='pb-2 text-center text-lg font-pop'>Cash Payments</h4>
                                        <p className='pb-2 text-center text-base font-pop'>Please contact</p>
                                        <p className='pb-2 text-center text-base font-pop'>Mr Owner Name</p>
                                        <p className='pb-2 text-center text-base font-pop font-bold' style={{ color: '#5A45CE' }}>+91848393093</p>
                                    </div>
                                    <div className='text-center mt-2'>
                                        <button className='btn-theme-color text-center h-10 px-5 font-pop' style={{ color: 'white', fontSize: '14px' }}>Change Payment</button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </section>
    );
};

export default SelectedPlan;