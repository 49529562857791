"use client";

import React from 'react';
import { Button, Modal } from "flowbite-react";

const DHomeModal = ({ openModal, setOpenModal }) => {
    return (
        <div>
            <Modal show={openModal} onClose={() => setOpenModal(false)} position={'center'}>

                <Modal.Body>
                    <div className="space-y-6 mx-auto text-center">
                        <div className='flex justify-center items-center'>
                            <img src='/assets/dHomeModSucc.png' alt='' width={300} />
                        </div>
                        <div>
                            <h3 className='font-pop font-bold text-xl md:text-2xl' style={{ lineHeight: '2.3rem' }}>Congratulations</h3>
                            <p className='font-pop font-bold text-sm md:text-base' style={{ lineHeight: '2.3rem' }}>Golden Money Tree Plan</p>
                            <p className='font-pop font-semibold text-xs md:text-sm' style={{ color: '#26BC00' }}>You Completed Level1 </p>
                        </div>
                        <div>
                            <p className='font-pop text-xs md:text-sm' style={{ color: '#263238' }}>Next level2 & need to Complete it within 365 days from  joining & they can claim 50 Lakhs i they withdraw tree after complete level2, same if they need to continue for Level3 they can complete get total outcome of 1 crore this entire should happen with 365 days from joining</p>
                        </div>
                        <div>
                            <p className='font-pop font-semibold text-xs md:text-sm' style={{ color: '#5A45CE' }}>Terms & Condition</p>
                            <button className='btn-theme-color font-pop text-sm md:text-base px-7 py-2 mt-2' style={{ color: 'white' }} onClick={() => setOpenModal(false)}>Done</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default DHomeModal;