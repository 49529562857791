import React, { useEffect } from 'react';
import './Home.css';
import Banner from './SubSections/Banner/Banner';
import StakingPlatform from './SubSections/StakingPlatform/StakingPlatform';
import HowToStake from './SubSections/HowToStake/HowToStake';
import UnlimitedBenefits from './SubSections/UnlimitedBenefits/UnlimitedBenefits';
import WhyChooseUs from './SubSections/WhyChooseUs/WhyChooseUs';
import { useParams } from 'react-router-dom';

const Home = () => {
    const { referral } = useParams();
    useEffect(() => {
        if (referral) {
            sessionStorage.setItem('uandcoreferral', referral)
        }
    }, [referral])
    return (
        <div>
            <Banner />
            <StakingPlatform />
            <HowToStake />
            <UnlimitedBenefits />
            <WhyChooseUs />
        </div>
    );
};

export default Home;