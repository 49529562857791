"use client";
import React from 'react';
import { Spinner, Table } from "flowbite-react";
import { dateFormat } from '../../../../core/helper/date-format';

const ResultTable = ({ data, loading }) => {
    // console.log(data)
    return (
        <div className='flex justify-center items-center mb-5 mt-16'>
            {(data && data.length > 0) && <div className='bg-white w-full px-3 py-4' style={{ borderRadius: '16px' }}>
                <div className="overflow-x-auto">
                    <Table className='table-fixed-custom'>
                        <Table.Head>
                            <Table.HeadCell className='table-cell-width'>S No</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Currency</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Quality</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>INR Amount</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Type</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Status</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Date</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {(loading && !data) && <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <Table.Cell className="table-cell-width">
                                    <Spinner aria-label="Center-aligned spinner example" size={'lg'} />
                                </Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                                <Table.Cell className="table-cell-width"></Table.Cell>
                            </Table.Row>}

                            {data && !loading && data.map((el, index) => {
                                const { currencyName, cryptoamount, amount, type, status, createdAt } = el;

                                return (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" id={index}>
                                        <Table.Cell>
                                            {index + 1}
                                        </Table.Cell>
                                        <Table.Cell className='table-cell-width'>{currencyName}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{cryptoamount}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{amount}</Table.Cell>
                                        <Table.Cell className='table-cell-width capitalize'>{type}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{status == 0 ? 'Rejected' : status == 1 ? 'Approved' : 'Pending'}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{dateFormat(createdAt)}</Table.Cell>
                                    </Table.Row>)
                            }
                            )}
                        </Table.Body>
                    </Table>
                </div>
            </div>}
        </div>
    );
};

export default ResultTable;