"use client";
import React, { useEffect, useState } from 'react';
import { Spinner, Table } from "flowbite-react";
import { getUserStakeData, getUserStakePayoutData } from '../../../../core/services/all.api';
import { dateFormatOnly } from '../../../../core/helper/date-format';

const DPayout = () => {
    const [getUserStake, setGetUserStake] = useState(null);
    const [loading, setLoading] = useState(true);
    const getData = async () => {
        setLoading(() => true)
        const stakeData = await getUserStakePayoutData()
        // console.log(stakeData)
        if (stakeData.status && (stakeData.data).length > 0) {
            setGetUserStake(() => stakeData.data)
        }
        else {
            setGetUserStake(() => null)
        }
        setLoading(() => false)
    }
    useEffect(() => {
        getData();
    }, [])
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>Payout</h4>
            </div>

            <div className='flex justify-center items-center my-5'>
                <div className='bg-white w-full px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className="overflow-x-auto">
                        <Table className='table-fixed-custom'>
                            <Table.Head>
                                <Table.HeadCell className="table-cell-width">Profile id</Table.HeadCell>
                                <Table.HeadCell className="table-cell-width">Plan</Table.HeadCell>
                                {/* <Table.HeadCell className="table-cell-width">Level</Table.HeadCell> */}
                                <Table.HeadCell className="table-cell-width">Close Date</Table.HeadCell>
                                <Table.HeadCell className="table-cell-width">Payout</Table.HeadCell>
                                <Table.HeadCell className="table-cell-width">Status</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {(loading && !getUserStake) && <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="table-cell-width">
                                        <Spinner aria-label="Center-aligned spinner example" size={'lg'} />
                                    </Table.Cell>
                                    <Table.Cell className="table-cell-width"></Table.Cell>
                                    <Table.Cell className="table-cell-width"></Table.Cell>
                                    <Table.Cell className="table-cell-width"></Table.Cell>
                                    <Table.Cell className="table-cell-width"></Table.Cell>
                                    {/* <Table.Cell className="table-cell-width"></Table.Cell> */}
                                </Table.Row>}
                                {(getUserStake && getUserStake.length > 0 && !loading) ? getUserStake.map((el, index) => {
                                    if (el?.withdrawStatus == 3) {
                                        return null
                                    }
                                    return (
                                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" id={index}>
                                            <Table.Cell className="table-cell-width" style={{ wordBreak: 'break-all' }}>
                                                {(el?._id).slice(14)}
                                            </Table.Cell>
                                            <Table.Cell className="table-cell-width">{el?.name} Money</Table.Cell>
                                            {/* <Table.Cell className="table-cell-width"><div className='status-card-red'><p className='text-xs sm:text-sm'>active</p></div></Table.Cell> */}
                                            <Table.Cell className="table-cell-width">{dateFormatOnly(el?.lastInterestDate)}</Table.Cell>
                                            <Table.Cell className="table-cell-width">{(el?.withdrawn)?.toFixed(2) || 0}</Table.Cell>
                                            <Table.Cell className="table-cell-width">{el?.withdrawStatus == 0 ? 'Pending' : el?.withdrawStatus == 1 ? 'Success' : el?.withdrawStatus == 2 ? 'Rejected' : 'Not Withdrawn'}</Table.Cell>
                                        </Table.Row>
                                    )
                                }

                                ) :
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                        <Table.Cell className="table-cell-width">No Data
                                        </Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                        {/* <Table.Cell className="table-cell-width"></Table.Cell> */}
                                        <Table.Cell className="table-cell-width"></Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DPayout;