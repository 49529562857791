import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import { getBankDetails } from '../../../../core/services/all.api';
import { makeRequest } from '../../../../core/services/v1/request';
import Config from '../../../../core/config';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';

const DepositWithdraw = () => {
    const { getUserBalance } = useGlobalContextData();
    const [tab, setTab] = useState(0);
    const [record, setRecord] = useState([]);
    const [bankDetails, setBankDetails] = useState({
        accountName: "",
        accountNumber: "",
        bankName: "",
        swiftIFSCCode: "",
        type: "",
        accountType: "",
        depositAddress: "",
    })
    const [record2, setRecord2] = useState([]);

    const getWithdrawRecord = async () => {
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'user-wallet/get-trans?type=withdraw' })
        if (res?.status) {
            setRecord2(() => res?.data)
        }
    }
    const getBankDetailsChild = async () => {

        const res = await getBankDetails()
        if (res?.status) {
            const bank = res?.data
            setBankDetails({
                accountName: bank?.accountName,
                accountNumber: bank?.accountNumber,
                bankName: bank?.bankName,
                swiftIFSCCode: bank?.swiftIFSCCode,
                type: bank?.type,
                accountType: bank?.accountType,
                depositAddress: bank?.depositAddress
            })
        }
    }
    const getDepositRecord = async () => {
        const res = await makeRequest({ method: 'GET', url: Config.V1_API_URL + 'user-wallet/get-trans?type=deposit' })
        if (res?.status) {
            setRecord(() => res?.data)
        }
    }
    useEffect(() => {
        getBankDetailsChild()
        getDepositRecord()
        getWithdrawRecord()
        getUserBalance();
    }, [])

    return (
        <div className='h-full'>
            <div className='flex justify-between items-start'>
                <div className='cursor-pointer'>
                    <h4 className='font-pop font-semibold text-lg md:text-xl' onClick={() => setTab(0)} style={{ color: 'black' }}><ArrowBackIcon /> Deposit</h4>
                </div>
                <div className='cursor-pointer'>
                    <h4 className='font-pop font-semibold text-lg md:text-xl' onClick={() => setTab(1)} style={{ color: 'black' }}>Withdraw <ArrowForwardIcon /></h4>
                </div>
            </div>

            {
                tab == 0 ? <Deposit record={record} bankDetails={bankDetails} getDepositRecord={getDepositRecord} /> : <Withdraw record={record2} getWithdrawRecord={getWithdrawRecord} />
            }

        </div>
    );
};

export default DepositWithdraw;