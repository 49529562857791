import React from 'react';
import './WhyChooseUs.css';

const WhyChooseUs = () => {
    return (
        <section className='pt-7 bg-sub-home'>
            <div className='container mx-auto px-4'>
                <div>
                    <h1 className='text-center text-2xl md:text-4xl home-title'>Why Choose U&Co?</h1>
                    <div className='flex justify-center items-center'>
                        <p className='text-xs md:text-sm text-justify home-sub-title pt-2'>Build & Scale your Project with our most Compressive set of<br />web3 Tools .We Provide wallets, markets, NFT tools and more</p>
                    </div>
                </div>
                <div className='pt-10 flex flex-col md:flex-row justify-between items-center' style={{ gap: '15px' }}>
                    <div className='w-full'>
                      <p className='why-choose-desc text-xl md:text-2xl'>We offers a seamless staking experience with a user-friendly interface and top-notch security. Our platform ensures that your digital assets are protected and all transactions are transparent. With competitive staking rewards, U&Co. is the perfect choice for growing your crypto efficiently. Join us and enjoy a secure and rewarding staking journey.</p>
                    </div>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                            <img src='/assets/char.png' alt='' className='img-fluid' width={500} />
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default WhyChooseUs;