import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './Pages/Dashboard/Dashboard.css';
import Header from './Components/Header/Header';
import Home from './Pages/Home/Home';
import Footer from './Components/Footer/Footer';
import ProfileProtected from './Components/ProtectedRoute/ProtectedRoute';
import Register from './Pages/Authentication/Register/Register';
import ChoosePlan from './Pages/ChoosePlan/ChoosePlan';
import SelectedPlan from './Pages/SelectedPlan/SelectedPlan';
import Dashboard from './Pages/Dashboard/Dashboard';
import DashboardHome from './Pages/Dashboard/SubPages/DashboardHome/DashboardHome';
import Geneology from './Pages/Dashboard/SubPages/Geneology/Geneology';
import DProfile from './Pages/Dashboard/SubPages/DProfile/DProfile';
import DPayout from './Pages/Dashboard/SubPages/DPayout/DPayout';
import UAndCoPlan from './Pages/Dashboard/SubPages/UAndCoPlan/UAndCoPlan';
import InstaTrade from './Pages/Dashboard/SubPages/InstaTrade/InstaTrade';
import WalletLogin from './Components/WalletModal/WalletLogin';
import WalletLoginWaring from './Components/WalletModal/WalletloginWarning';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-input-2/lib/style.css';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import UAndCoPlanDetails from './Pages/Dashboard/SubPages/UAndCoPlan/UAndCoPlanDetails';
import KycBank from './Pages/KycBank/KycBank';
import Deposit from './Pages/Dashboard/SubPages/DepositWithdraw/Deposit';
import DepositWithdraw from './Pages/Dashboard/SubPages/DepositWithdraw/DepositWithdraw';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';

function App() {
  const pathname = useLocation().pathname;
  const avoidFooter = pathname.includes('/sign-up') || pathname.includes('/kyc') || pathname.includes('/choose-plan') || pathname.includes('/selected-plan') || pathname.includes('/log-in') || pathname.includes('/dashboard');
  const avoidHeader = false;

  const dashboardRoutes = [
    { path: "home", component: <DashboardHome />, id: 9 },
    { path: "home", component: <DashboardHome />, id: 10 },
    { path: "geneology", component: <Geneology />, id: 11 },
    { path: "profile", component: <DProfile />, id: 12 },
    { path: "payout", component: <DPayout />, id: 13 },
    { path: "insta-trade", component: <InstaTrade />, id: 14 },
    { path: "uandco", component: <UAndCoPlan />, id: 15 },
    { path: "uandco/:id", component: <UAndCoPlanDetails />, id: 16 },
    { path: "deposit-withdraw", component: <DepositWithdraw />, id: 17 },
  ]

  const routes = [
    { path: '/', component: <Home />, id: 1 },
    { path: '/:referral', component: <Home />, id: 1 },
    { path: '/home', component: <Home />, id: 2 },
    { path: '/home/:referral', component: <Home />, id: 2 },
    { path: '/sign-up', component: <Register />, id: 3 },
    // { path: '/log-in', component: <Login />, id: 4 },
    { path: '/kyc-bank', component: <KycBank />, id: 5 },
    { path: '/choose-plan', component: <ChoosePlan />, id: 6 },
    { path: '/selected-plan', component: <SelectedPlan />, id: 7 },
    { path: "/dashboard/*", component: <ProfileProtected><Dashboard /></ProfileProtected>, children: dashboardRoutes, id: 8 },
    { path: '/about', component: <About />, id: 9 },
    { path: '/contact', component: <Contact />, id: 10 },
    // { path: "*", component: <NotFound />, id: 7 },
  ];

  return (
    <>
      {!avoidHeader ? <Header /> : null}
      <WalletLogin />
      <WalletLoginWaring />
      <ScrollToTop />
      <ToastContainer closeOnClick />
      <Routes>
        {
          routes.map(route => {
            return (
              <Route path={route?.path} element={route?.component} key={route?.id}>
                {route?.children && (
                  route?.children.map((child, index) => {
                    return index === 0 ?
                      (<Route key={child?.id} index element={child?.component} />) :
                      (<Route key={child?.id} path={child?.path} element={child?.component} />)
                  })
                )}
              </Route>
            )
          })
        }
      </Routes>
      {!avoidFooter ? <Footer /> : null}
    </>
  );
}

export default App;
