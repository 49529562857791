import React, { useEffect, useState } from 'react';
import Flow from './FlowChartHome/FlowChartHome';
import { getUserReferralData, getUserStakeData } from '../../../../core/services/all.api';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';

const Geneology = () => {
    const { getReferralData, getReferralStake } = useGlobalContextData();
    const [activeTab, setActiveTab] = useState(1);
    // const [getUserStake, setGetUserStake] = useState({});
    // const [getReferralStake, setGetReferralStake] = useState([]);

    // const getData = async () => {
    //     const stakeData = await getUserStakeData()
    //     if (stakeData.status && (stakeData.data).length > 0) {
    //         setGetUserStake(() => stakeData.data)
    //     }
    //     else {
    //         setGetUserStake(() => { })
    //     }
    // }
    // const getReferralData = async () => {
    //     const stakeData = await getUserReferralData(user?.walletAddress)
    //     if (stakeData.status && (stakeData.data).length > 0) {
    //         setGetReferralStake(() => stakeData.data[0]?.referralUser)
    //     }
    //     else {
    //         setGetReferralStake(() => [])
    //     }
    // }

    useEffect(() => {
        // getData();
        getReferralData()
    }, [])
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>Geneology</h4>
            </div>
            <div className='flex justify-center items-center my-8'>
                <div className={`${activeTab == 1 ? ' acTab ' : ' deAcTab '} common-transition text-xs sm:text-sm xl:text-base w-fit px-10 py-3 h-full flex justify-center items-center`} onClick={() => setActiveTab(() => 1)}>
                    <p>Easy Money Tree Plan</p>
                </div>
                {/* <div className='w-full md:w-3/4 xl:w-1/2 h-12 md:h-14 tabGeneology'>
                    <div className={`${activeTab == 1 ? ' acTab ' : ' deAcTab '} common-transition text-xs sm:text-sm xl:text-base w-full md:w-3/4 xl:w-1/2 h-full flex justify-center items-center`} onClick={() => setActiveTab(() => 1)}>
                        <p>Easy Money Tree Plan</p>
                    </div>
                    <div className={`${activeTab == 2 ? ' acTab ' : ' deAcTab '} common-transition text-xs sm:text-sm xl:text-base w-full md:w-3/4 xl:w-1/2 h-full flex justify-center items-center`} onClick={() => setActiveTab(() => 2)}>
                        <p>Golden Money Tree Plan</p>
                    </div>
                </div> */}
            </div>
            <div className='w-full h-full'>
                <Flow getReferralStake={getReferralStake} />
            </div>
        </div>
    );
};

export default Geneology;