import React, { useEffect, useState } from 'react';
import BuyTab from './BuyTab';
import SellTab from './SellTab';
import ResultTable from './ResultTable';
import '../../Dashboard.css'
import { makeRequest, makeRequestWihActualStatus } from '../../../../core/services/v1/request';
import Config from '../../../../core/config';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { getBankDetails } from '../../../../core/services/all.api';

const InstaTrade = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [loading2, setLoading2] = useState(false);
    const [buyData, setBuyData] = useState([]);
    const [loading3, setLoading3] = useState(false);
    const [sellData, setSellData] = useState([]);
    const [bankDetails, setBankDetails] = useState({
        accountName: "",
        accountNumber: "",
        bankName: "",
        swiftIFSCCode: "",
        type: "",
        accountType: "",
        depositAddress: "",
    })
    const { getPairDetails, pairDetails, userWallet, getUserBalance } = useGlobalContextData();

    const getInstabuyTrade = async () => {

        setLoading2(() => true)
        const params = {
            url: `${Config.V1_API_URL}insta-trade/get-insta/buy`,
            method: 'GET',
        }

        const response = (await makeRequestWihActualStatus(params));

        if (response.status) {
            setBuyData(() => response?.data)
            getUserBalance();
            setLoading2(() => false)
        }
        else {
            setLoading2(() => false)
        }
        setLoading2(() => false)
    }
    const getInstaSellTrade = async () => {

        setLoading3(() => true)
        const params = {
            url: `${Config.V1_API_URL}insta-trade/get-insta/sell`,
            method: 'GET',
        }

        const response = (await makeRequestWihActualStatus(params));

        if (response.status) {
            setSellData(() => response?.data)
            getUserBalance();
            setLoading3(() => false)
        }
        else {
            setLoading3(() => false)
        }
        setLoading3(() => false)
    }
    const getBankDetailsChild = async () => {

        const res = await getBankDetails()
        if (res?.status) {
            const bank = res?.data
            setBankDetails({
                accountName: bank?.accountName,
                accountNumber: bank?.accountNumber,
                bankName: bank?.bankName,
                swiftIFSCCode: bank?.swiftIFSCCode,
                type: bank?.type,
                accountType: bank?.accountType,
                depositAddress: bank?.depositAddress
            })
        }
    }
    useEffect(() => {
        getPairDetails();
        getInstabuyTrade();
        getInstaSellTrade();
        getUserBalance();
        getBankDetailsChild()
    }, [])
    return (
        <div className='h-full'>
            <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>Insta Trade</h4>
            </div>

            <div className='flex justify-center items-center my-5'>
                <div className='bg-white w-full md:w-3/4 xl:w-1/3 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='relative'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 35 35" fill="none" style={{ position: 'absolute', left: 11 }}>
                            <path d="M12.7603 7.90625L4.66699 15.9996L12.7603 24.0929" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M27.3336 16H4.89355" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <h4 className='font-pop font-semibold text-lg md:text-xl text-center' style={{ color: 'black' }}>Insta Trade</h4>
                    </div>
                    <div className='flex justify-between items-center px-3 md:px-4 pt-5'>
                        <div className='w-full h-12 tabGeneology'>
                            <div className={`${activeTab == 1 ? ' acTab ' : ' deAcTab '} common-transition text-xs  sm:text-sm xl-text-base w-full  h-full flex justify-center items-center`} onClick={() => setActiveTab(() => 1)}>
                                <p>Buy</p>
                            </div>
                            <div className={`${activeTab == 2 ? ' acTab ' : ' deAcTab '} common-transition text-xs  sm:text-sm xl:text-base w-full  h-full flex justify-center items-center`} onClick={() => setActiveTab(() => 2)}>
                                <p>Sell</p>
                            </div>
                        </div>
                    </div>

                    {
                        activeTab == 1 ?
                            <BuyTab pairDetails={pairDetails} getUserBalance={getUserBalance} userWallet={userWallet} getInstabuyTrade={getInstabuyTrade} /> : activeTab == 2 ? <SellTab pairDetails={pairDetails} getInstaSellTrade={getInstaSellTrade} getUserBalance={getUserBalance} userWallet={userWallet} bankDetails={bankDetails} /> : null
                    }
                </div>
            </div>

            <ResultTable data={activeTab == 1 ? buyData : sellData} loading={activeTab == 1 ? loading2 : loading3} />
        </div>
    );
};

export default InstaTrade;