import React, { useEffect, useState } from 'react';
import Config from '../../../core/config';
import { makeRequestWihActualStatus, makeRequestWithErrorMessage } from '../../../core/services/v1/request';
import { toast } from '../../../core/lib/toastAlert';
import { Spinner } from 'flowbite-react';

const Step1 = ({ step, setStep, user, setUser }) => {
    const [referral, setReferral] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (user?.steps?.step1) {
            return setStep(() => 2)
        }
    }, [user, step])
    const onSubStep1 = async (e) => {
        e.preventDefault();
        setLoading(() => true);
        const data = {
            referralId: referral,
            firstName: e.target.firstName.value,
            lastName: e.target.lastName.value
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/sign-up?step=1`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            setUser((data) => { return { ...data, steps: { ...data?.steps, step1: true } } })
            setStep(() => 2)
            // toast({ type: 'success', message: res.message })
            e.target.reset();
            setLoading(() => false);
        }
        else {
            toast({ type: 'error', message: res.message })
            setLoading(() => false);
        }
        setLoading(() => false);

    }
    const checkValidity = async (e) => {
        const params = {
            url: `${Config.V1_API_URL}wallet-user/validity?walletAddress=${e.target.value}`,
            method: 'GET',
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            setReferral(() => e.target.value)
            // toast({ type: 'success', message: res.message })
        }
        else {
            setReferral(() => '')
            sessionStorage.clear('uandcoreferral')
            return toast({ type: 'error', message: res.message })
        }
    }
    useEffect(() => {
        if (sessionStorage.getItem('uandcoreferral')) {
            setReferral(() => sessionStorage.getItem('uandcoreferral'))
            checkValidity({ target: { value: sessionStorage.getItem('uandcoreferral') } })
        }
    }, [])
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={onSubStep1} className='form-inputs mx-auto px-3 md:px-8'>
                    <label className='text-sm md:text-base'>Wallet Address *</label><br />
                    <input className='text-sm md:text-base' name='walletAddress' type='text' value={user?.walletAddress} disabled required /> <br />
                    <label className='text-sm md:text-base'>Referral-id</label><br />
                    <input className='text-sm md:text-base' name='referralId' disabled={sessionStorage.getItem('uandcoreferral')} value={referral} onChange={(e) => {
                        if (sessionStorage.getItem('uandcoreferral')) {
                            setReferral(() => sessionStorage.getItem('uandcoreferral'))
                        }
                        else {
                            setReferral(() => e.target.value)
                        }

                    }} onBlur={checkValidity} type='text' /> <br />
                    <label className='text-sm md:text-base' >First-Name *</label><br />
                    <input className='text-sm md:text-base' name='firstName' type='text' required /> <br />
                    <label className='text-sm md:text-base'>Last-Name*</label><br />
                    <input className='text-sm md:text-base' name='lastName' type='text' required /> <br />
                    <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                        <span className="pl-3">Loading...</span></> : 'Next'}</button>
                </form>
            </div>
        </div>
    );
};

export default Step1;