"use client";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Navbar } from "flowbite-react";
import { Dropdown } from "flowbite-react";
import './Header.css'
import { useGlobalContextData } from "../../core/context/AdminContext/GlobalContext";
import DashboardIcon from '@mui/icons-material/Dashboard';
import Logout from '@mui/icons-material/Logout';

export default function Header() {
    const navigate = useNavigate();
    const pathname = useLocation().pathname;
    const { openWalletModal, user, connectToTrustWallet, logOut } = useGlobalContextData();

    const paths = [
        { path: '/home', name: 'Home' },
        // { path: '/investment', name: 'Investment' },
        { path: '/about', name: 'About' },
        { path: '/contact', name: 'Contact' },
        {
            path: '/', isLog: true, name: user?.walletAddress ? <Dropdown size={'sm'} label="" dismissOnClick={true} renderTrigger={() => <Button color="light" pill className="px-0 md:px-2 py-0 ml-2 md:ml-auto mt-2 md:mt-auto h-10 md:h-auto">
                Dashboard
            </Button>}>
                <Dropdown.Item onClick={() => {
                    navigate('/dashboard');
                }} ><DashboardIcon fontSize="small" className="-ml-1 mr-2" />Dashboard</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    logOut();
                }} ><Logout fontSize="small" className="-ml-1 mr-2" />Log Out</Dropdown.Item>
            </Dropdown>
                :
                <Dropdown size={'sm'} label="" dismissOnClick={true} renderTrigger={() => <Button color="light" pill className="px-0 md:px-2 py-0 ml-2 md:ml-auto mt-2 md:mt-auto h-10 md:h-auto">
                    Connect Wallet
                </Button>}>
                    <Dropdown.Item onClick={() => {
                        openWalletModal();
                    }} ><img src="/assets/metamask.png" alt="metamask" className=' pe-2' width={40} />Meta Mask</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        connectToTrustWallet();
                    }} ><img src="/assets/trust.png" alt="trust wallet" className='pe-2' width={40} />Trust Wallet</Dropdown.Item>
                </Dropdown>
        },
    ]
    return (
        <div style={{ background: '#5A45CE' }} className={`${pathname.includes('/dashboard') ? 'sticky top-0 z-50' : ''}`}>
            <Navbar style={{ background: '#5A45CE' }} className="py-4 container mx-auto px-4">
                <Navbar.Brand as={Link} to='/'>
                    <img src="/assets/logo.png" width={180} alt="Flowbite React Logo" />
                    {/* <span className="self-center whitespace-nowrap text-xl md:text-2xl font-bold text-white header-nav-logo">Logo</span> */}
                </Navbar.Brand>
                <Navbar.Toggle className="text-gray-400 bg-white h-10" />
                <Navbar.Collapse className="custom-nav-align">
                    {
                        paths.map(data => {
                            return (

                                data?.isLog ? <span id={data?.path} as={Link} className={`bg-transparent w-fit md:w-auto md:bg-transparent active:bg-transparent md:text-white md:hover:text-slate-300 md:active:text-white text-white hover:text-slate-300 active:text-white leading-0 md:leading-7 custom-transform-border  ${((pathname === data?.path && !data?.isLog)) ? 'md:border-b-4 border-b-4 border-white-500 custom-btn-login' : 'border-0'}`}>{data?.name}</span> : <Navbar.Link id={data?.path} as={Link} to={data?.path} className={`bg-transparent text-sm w-fit md:w-auto md:bg-transparent active:bg-transparent md:text-white md:hover:text-slate-300 md:active:text-white text-white hover:text-slate-300 active:text-white leading-0 md:leading-7 custom-transform-border ${((pathname === data?.path && !data?.isLog)) ? 'md:border-b-4 border-b-4 border-white-500 custom-btn-login' : 'border-0'}`}>
                                    {data?.name}
                                </Navbar.Link>

                            )
                        })
                    }

                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
