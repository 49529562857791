import React, { useEffect, useRef, useState } from 'react';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Spinner } from 'flowbite-react';
import { makeRequest, makeRequestWithErrorMessage } from '../../../../core/services/v1/request';
import Config from '../../../../core/config';
import RecordTable from './RecordTable';
import { useGlobalContextData } from '../../../../core/context/AdminContext/GlobalContext';
import { toast } from '../../../../core/lib/toastAlert';

const Withdraw = ({ record, getWithdrawRecord }) => {
    const { userWallet, getUserBalance, user } = useGlobalContextData();
    const [loading, setLoading] = useState(false);

    const onSubTransWithdraw = async (e) => {
        e.preventDefault();
        if (e.target.enterAmount.value > userWallet?.amount) {
            return toast({ type: 'error', message: 'Insufficient Balance' })
        }
        setLoading(() => true)

        const data = {
            amount: e.target.enterAmount.value,
            currency: 'INR',
        }

        const params = {
            url: `${Config.V1_API_URL}user-wallet/create-trans?type=withdraw`,
            method: 'POST',
            body: data
        }
        const res = await makeRequestWithErrorMessage(params)
        if (res.status) {
            toast({ type: 'success', message: res.message })
            e.target.reset();
            getWithdrawRecord()
            getUserBalance();
            setLoading(() => false)
        }
        else {
            setLoading(() => false)
            toast({ type: 'error', message: res.message })
        }

        setLoading(() => false)
    }

    return (
        <div className='h-full'>
            {/* <div>
                <h4 className='font-pop font-semibold text-lg md:text-xl' style={{ color: 'black' }}>Withdraw</h4>
            </div> */}

            <div className='flex justify-between items-start gap-8 flex-col lg:flex-row my-5'>


                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>Withdraw Information</p>
                        </div>
                        <div>
                            {/* <p className='font-pop text-sm md:text-base capitalize' style={{ fontWeight: 400, color: 'var(--T, #313131)' }}>[{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}]</p> */}
                        </div>
                    </div>

                    <div className='pt-5 pb-2'>
                        <form onSubmit={onSubTransWithdraw} className='dProfile-form-inputs mx-auto px-3 md:px-8'>
                            <label className='text-sm md:text-base'>Account Number</label><br />
                            <input className='text-sm md:text-base' type='text' name='accountNumber' value={user?.bankDetails?.accountNumber} readOnly />
                            <br />
                            <label className='text-sm md:text-base'>Enter Amount</label><br />
                            <input className='text-sm md:text-base' type='text' name='enterAmount' /><br />
                            <label className='text-sm md:text-base'>Balance : {userWallet?.amount} {userWallet?.currency}</label><br />
                            <button type='submit' className='btn-theme-color text-base' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                <span className="pl-3">Loading...</span></> : 'Submit'}</button>
                        </form>
                    </div>
                </div>

                <div className='bg-white w-full md:w-3/4 xl:w-1/2 px-3 py-4' style={{ borderRadius: '16px' }}>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-pop text-lg md:text-xl' style={{ fontWeight: 600, color: '#5A45CE' }}>The amount would be transferred to</p>
                        </div>
                        <div>
                            {/* <p className='font-pop text-sm md:text-base capitalize' style={{ fontWeight: 400, color: 'var(--T, #313131)' }}>[{user?.kyc?.panStatus == 0 ? 'incomplete' : user?.kyc?.panStatus == 1 ? 'pending' : user?.kyc?.panStatus == 2 ? 'rejected' : user?.kyc?.panStatus == 3 ? 'approved' : ''}]</p> */}
                        </div>
                    </div>

                    <div className='px-3 pt-5 pb-2'>
                        <div>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Account Name :</span> {user?.bankDetails?.accountName}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Account Number :</span> {user?.bankDetails?.accountNumber}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>Bank Name :</span> {user?.bankDetails?.bankName}
                            </p>
                            <p className='font-pop font-normal' style={{ color: '#313131', lineHeight: '2.3rem' }}>
                                <span className='font-bold'>IFSC Code :</span> {user?.bankDetails?.ifscCode}
                            </p>
                            <p className='font-pop font-normal pt-2' style={{ color: '#313131' }}>
                                <li>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this account.</li>
                                <li>UPI Apps like gPay, PhonePe, Paytm are available.</li>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <p className='font-pop text-lg md:text-xl relative top-3' style={{ fontWeight: 600, color: '#5A45CE', display: record && record.length > 0 ? '' : 'none' }}>Withdraw Record</p>
            <RecordTable data={record} />
        </div>
    );
};

export default Withdraw;