"use client";
import { Button, Checkbox, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useState } from "react";

export default function WithdrawBankModal({ openModal, setOpenModal, withdrawEasy, loading }) {

    function onCloseModal() {
        setOpenModal(false);
    }
    const onSubWithdraw = async (e) => {
        e.preventDefault();

        const accountName = e.target.accountName.value;
        const accountNumber = e.target.accountNumber.value;
        const ifscCode = e.target.ifscCode.value;
        const bankName = e.target.bankName.value;
        // console.log(
        //     accountName,
        //     accountNumber,
        //     ifscCode,
        //     bankName,)

        await withdrawEasy(accountName, accountNumber, ifscCode, bankName);
        onCloseModal()
    }
    return (
        <>
            <Modal show={openModal} size="md" onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <form onSubmit={onSubWithdraw}>
                        <div className="space-y-3">
                            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add Bank Details</h3>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="name" value="Account Name" />
                                </div>
                                <TextInput
                                    id="name"
                                    placeholder=""
                                    required
                                    name="accountName"
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="number" value="Account Number" />
                                </div>
                                <TextInput
                                    id="number"
                                    placeholder=""
                                    required
                                    name="accountNumber"
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="code" value="IFSC Code" />
                                </div>
                                <TextInput
                                    id="code"
                                    placeholder=""
                                    required
                                    name="ifscCode"
                                />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="bName" value="Bank Name" />
                                </div>
                                <TextInput
                                    id="bName"
                                    placeholder=""
                                    required
                                    name="bankName"
                                />
                            </div>
                            <div className="w-full">
                                <Button className="btn-theme-color" type="submit">{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                                    <span className="pl-3">Loading...</span></> : 'Withdraw'}</Button>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
