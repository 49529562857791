import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../../core/config';
import { makeRequestWihActualStatus } from '../../../core/services/v1/request';
import { toast } from '../../../core/lib/toastAlert';
import { Spinner } from 'flowbite-react';

const Step3 = ({ user, step, setStep, setUser }) => {
    const [date, setDate] = useState("");
    const [dateCom, setDateCom] = useState("");
    const [loading, setLoading] = useState(false);
    const inputDate1 = useRef(null);
    useEffect(() => {
        if (user?.steps?.step3) {
            return setStep(() => 6)
        }
    }, [user, step])
    const onSubStep2 = async (e) => {
        e.preventDefault();
        setLoading(() => true);
        const data = {
            dob: date,
            gender: e.target.gender.value,
        }
        const params = {
            url: `${Config.V1_API_URL}wallet-user/sign-up?step=3`,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'Application/json',
            },
        }
        const res = await makeRequestWihActualStatus(params)
        if (res.status) {
            setUser((data) => { return { ...data, steps: { ...data?.steps, step3: true } } })
            setStep(() => 6)
            // toast({ type: 'success', message: res.message })
            e.target.reset();
            setLoading(() => false);
        }
        else {
            setLoading(() => false);
            toast({ type: 'error', message: res.message })
        }
        setLoading(() => false);
    }
    const formatDate = (inputDate) => {
        const dateObject = new Date(inputDate);

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = dateObject.getDate();
        const month = months[dateObject.getMonth()];
        const year = dateObject.getFullYear();

        const day2 = dateObject.getDate().toString().padStart(2, "0");
        const month2 = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const year2 = dateObject.getFullYear();

        // const formattedDate = `${day} ${month} ${year}`;
        const formattedDate = `${day2}-${month2}-${year2}`
        return formattedDate;
    };
    const fixingDateCom = (e) => {
        const formattedDate = formatDate(e);
        setDate(() => e);
        setDateCom(() => formattedDate);
    };
    return (
        <div>
            <div className='py-8 px-3 md:px-8'>
                <img src='/assets/sign-up3.png' alt='' className='img-fluid' />
            </div>
            <div>
                <form onSubmit={onSubStep2} className='mx-auto px-3 md:px-8'>
                    <div className='form-inputs'>
                        <label className='text-sm md:text-base'>Enter Date of Birth *</label>
                    </div>
                    <DatePicker
                        type="date"
                        selected={date}
                        autoComplete="off"
                        onChange={(date) => {
                            fixingDateCom(date);
                        }}

                        placeholderText="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="dateOfCompletion"
                        className="datePickerClass"
                        ref={inputDate1}
                        required
                        onClick={() => inputDate1.current.showPicker()}
                    />
                    <div className='form-inputs'>
                        <label className='text-sm md:text-base'>Gender *</label><br />
                        <select name='gender' required>
                            <option className='hidden'>Select Gender</option>
                            <option value='Male' key='Male'>Male</option>
                            <option value='Female' key='Female'>Female</option>
                            <option value='Others' key='Others'>Others</option>
                        </select>
                        <br />
                        <button type='submit' className='btn-theme-color text-base md:text-lg' disabled={loading}>{loading ? <><Spinner aria-label="Spinner button example" size="sm" />
                            <span className="pl-3">Loading...</span></> : 'Next'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Step3;