"use client";
import React from 'react';
import { Table } from "flowbite-react";
import { dateFormat } from '../../../../core/helper/date-format';

const RecordTable = ({ data, loading }) => {
    // console.log(data)
    return (
        <div className='flex justify-center items-center mb-5 mt-5'>
            {(data && data.length > 0) && <div className='bg-white w-full px-3 py-4' style={{ borderRadius: '16px' }}>
                <div className="overflow-x-auto">
                    <Table className='table-fixed-custom'>
                        <Table.Head>
                            <Table.HeadCell className='table-cell-width'>S No</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Time</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Type</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Asset</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Amount</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Destination</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>TxID</Table.HeadCell>
                            <Table.HeadCell className='table-cell-width'>Status</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {data && data.map((el, index) => {
                                const { currency, txnId='N/A', amount, type, status, createdAt } = el;

                                return (
                                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" id={index}>
                                        <Table.Cell>
                                            {index + 1}
                                        </Table.Cell>
                                        <Table.Cell className='table-cell-width'>{dateFormat(createdAt)}</Table.Cell>
                                        <Table.Cell className='table-cell-width capitalize'>{type}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{currency}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{amount}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{'Bank'}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{txnId?txnId:'N/A'}</Table.Cell>
                                        <Table.Cell className='table-cell-width'>{status == 0 ? 'Pending' : status == 1 ? 'Completed' : status == 2 ? 'Cancelled' : status == 3 ? 'Pending' : ''}</Table.Cell>

                                    </Table.Row>)
                            }
                            )}
                        </Table.Body>
                    </Table>
                </div>
            </div>}
        </div>
    );
};

export default RecordTable;